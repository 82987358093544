import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import { IPalimpsestItem } from "../../../redux/sport/sportSlice";
import {
    getEventForQuickBetRequested,
    addOddInCoupon,
    getEventForQuickBetSucceded,
    saveBetCodes,
} from "../../../redux/coupon/couponSlice";
import { openDialog } from "../../../redux/dialog/dialogSlice";
import styles from "./styles.module.scss";

function QuickBet(): ReactElement {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [short, setShort] = useState<string>("");
    const [isOddSelectDisabled, setOddSelectDisabled] = useState(true);
    const [isOddPresentInCoupon, setIsOddPresent] = useState(false);
    const shortSelectRef = useRef<any>(null);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion
            expanded={expanded === "panel"}
            onChange={handleChange("panel")}
            className={styles.accordion}
        >
            <AccordionSummary
                expandIcon={<FontAwesomeIcon type="fas" name="chevron-down" />}
                className={styles.accordionSummary}
            >
                <Translate value="coupon.quickBet" />
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
                <Box className={styles.quickBetFields}>
                    <ShortField
                        short={short}
                        setShort={setShort}
                        shortSelectRef={shortSelectRef}
                        setOddSelectDisabled={setOddSelectDisabled}
                        isOddPresentInCoupon={isOddPresentInCoupon}
                    />
                    <OddSelect
                        shortSelectRef={shortSelectRef}
                        short={short}
                        setShort={setShort}
                        isOddSelectDisabled={isOddSelectDisabled}
                        setOddSelectDisabled={setOddSelectDisabled}
                        setIsOddPresent={setIsOddPresent}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
export default QuickBet;
interface IShortField {
    shortSelectRef: any;
    short: string;
    setShort: React.Dispatch<React.SetStateAction<string>>;
    setOddSelectDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isOddPresentInCoupon: boolean;

}

function ShortField({
    short,
    setShort,
    shortSelectRef,
    setOddSelectDisabled,
    isOddPresentInCoupon,
}: IShortField): ReactElement {
    const dispatch = useAppDispatch();
    const sports = useAppSelector((state) => state.sport.sports);
    const [event, setEvent] = useState<IPalimpsestItem | undefined>(undefined);

    const handleButtonClick = async () => {
        try {
            const response = await fetch('/betcodes.json');
            const data = await response.json();
            if (data) {
                dispatch(openDialog({ type: "betcodes" }))
                dispatch(saveBetCodes(data))
            }
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }
    };
    const handleChange = (event: any) => {
        setShort(event.target.value);
        if (event.key === "Enter") {
            event.preventDefault();
            setOddSelectDisabled(false)
            sports && short && findEventParentSportId(short, sports);
        }
    };

    const findEventParentSportId = (short: string, sportData: IPalimpsestItem[]) => {
        let event: IPalimpsestItem | undefined = undefined;
        sportData.forEach((item) => {
            if (item.children && item.children.length > 0) {
                findEventParentSportId(short, item.children);
            }

            if (
                item.type === "tournament" &&
                item.children &&
                item.children.find((el) => el.short === Number(short))
            ) {
                event = item.children.find((el) => el.short === Number(short));
            }
        });
        event && setEvent(event);
    };
    useEffect(() => {
        event && dispatch(getEventForQuickBetRequested(event.id));
    }, [event]);
    useEffect(() => {
        event && !short && setEvent(undefined);
    }, [short]);
    return (
        <>
            <Box className={styles.eventFieldContainer}>
                <TextField
                    value={short || ""}
                    variant="outlined"
                    label={<Translate value="coupon.selectEvent" />}

                    inputRef={shortSelectRef}
                    onKeyDown={(event) => handleChange(event)}
                    onChange={(event) => handleChange(event)}
                    className={[styles.eventField, "form-label"].join(" ")}
                />
            </Box>

            {isOddPresentInCoupon ?
                <Box className={styles.eventContainerLabel}>
                    <Box className={styles.quickBetCodes} onClick={handleButtonClick}>
                        <Typography className={styles.quickBetCodesLabel} >
                            BETCODES
                        </Typography>
                        <FontAwesomeIcon type="fal" name="file-lines" size="25px" />
                    </Box>
                    <Typography className={styles.eventLabel}>
                        <Translate value="coupon.quickoddMsg" />
                    </Typography>
                </Box>
                :
                <Box className={styles.eventContainer}>
                    <Box className={styles.quickBetCodes} onClick={handleButtonClick}>
                        <Typography className={styles.quickBetCodesLabel} >
                            BETCODES
                        </Typography>
                        <FontAwesomeIcon type="fal" name="file-lines" size="25px" />
                    </Box>
                    <Typography
                        textAlign="end"
                        className={styles.eventLabel}>
                        {event?.home} -
                    </Typography>
                    {event?.label &&
                        <Typography textAlign="center" className={styles.eventLabelCenter}>-</Typography>}
                    <Typography
                        className={styles.eventLabel}>
                        &nbsp;{event?.away}
                    </Typography>
                </Box>
            }
        </>
    );
}

interface IOddSelect {
    short: string;
    setShort: React.Dispatch<React.SetStateAction<string>>;
    isOddSelectDisabled: boolean;
    setOddSelectDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOddPresent: React.Dispatch<React.SetStateAction<boolean>>;
    shortSelectRef: any;
}

function OddSelect({
    setShort,
    shortSelectRef,
    isOddSelectDisabled,
    setOddSelectDisabled,
    setIsOddPresent
}: IOddSelect): ReactElement {
    const dispatch = useAppDispatch();
    const oddSelectRef = useRef<any>(null);
    const odds = useAppSelector((state) => state.coupon.odds);
    const quickbetOdds = useAppSelector((state) => state.coupon.quickbetOdds);
    const marketsConfig = useAppSelector((state) => state.sport.marketsConfig);
    const [composedOptions, setOptions] = useState<any>(undefined);
    const [input, setInput] = useState<string>("");
    const [odd, setOdd] = useState<any>(null);

    const filterOptions = createFilterOptions({

        stringify: (option: any) => option.quick,
    });

    const handleChange = (event: any, value: any) => {
        if (value !== null) {
            const exists = odds.find(el => el.unique === value.unique)
            if (!exists) {
                setIsOddPresent(false)
                setOdd(value);
                dispatch(
                    addOddInCoupon({
                        eventId: value.eventId,
                        odd: {
                            value: value.value,
                            id: value.id,
                            eventId: value.eventId,
                            marketId: value.marketId,
                            oddLabel: value.label,
                            marketLabel: value.marketLabel,
                            spreadLabel: value.hasSpread ? value.spread : undefined,
                            unique: value.unique,
                            code: value.code,
                            fix: false,
                            locked: value.locked,
                            caller: "sport",
                        },
                    })
                );
                setOdd(null);
                setInput("");
                setShort("");
                setOddSelectDisabled(true);
                quickbetOdds &&
                    dispatch(getEventForQuickBetSucceded(undefined));
            } else {
                setIsOddPresent(true)
                setOdd(null);
                setInput("");
                setShort("");
                setOddSelectDisabled(true);
                quickbetOdds &&
                    dispatch(getEventForQuickBetSucceded(undefined));
            }
        }
    };
    useEffect(() => {
        let newOptions: any = [];
        if (quickbetOdds && marketsConfig) {
            quickbetOdds.map((odd: any) => {
                const marketFromConfig = marketsConfig.find(
                    (item: any) => item.id === odd.marketId
                );
                const oddLabelFromConfig = marketFromConfig?.odds.find(
                    item => item.id === odd.id
                )?.label;
                newOptions = [
                    ...newOptions,
                    {
                        ...{
                            ...odd,
                            label: oddLabelFromConfig,
                        },
                        marketLabel: marketFromConfig?.label,
                        sportId: marketFromConfig?.sport_id,
                        hasSpread: marketFromConfig?.spread,
                    },
                ];
            });
            setOptions(newOptions);
        } else {
            setOptions(undefined);
        }
    }, [quickbetOdds, marketsConfig]);
    useEffect(() => {
        quickbetOdds ? oddSelectRef.current.focus()
            : shortSelectRef.current.focus()
    }, [quickbetOdds]);

    return (
        <Autocomplete
            autoHighlight
            isOptionEqualToValue={(option, newValue) => {
                return option.id === newValue.id;
            }}
            inputValue={input || ""}
            value={odd || null}
            filterOptions={filterOptions}
            options={composedOptions || []}
            getOptionLabel={(option: any) =>
                `${option.label} ${option.hasSpread && option.spread != 0 ? `(${option.spread})` : ""
                } `
            }
            renderOption={(props, option: any) => (
                <Box component="li" {...props} className={styles.textFieldOption}>
                    {option.label}&nbsp;
                    {option.hasSpread && option.spread != 0 ? `(${option.spread})` : ""}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={<Translate value="coupon.options" />}
                    inputRef={oddSelectRef}
                    variant="outlined"
                    className={[styles.autocomplete, "form-label"].join(" ")}
                />
            )}
            disabled={isOddSelectDisabled}
            onChange={(event, value) => handleChange(event, value)}
            onInputChange={(event, value) => setInput(value)}
            className={styles.autocompleteContainer}
        />
    );
}
