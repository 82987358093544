import React, { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Box from "@mui/material/Box";
import FontAwesomeIcon from "../FontAwesomeIcon";

import { addOddInCoupon, removeOddFromCoupon } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
import { convertOddValue, recursiveSearch, useWindowSize } from "../../../services/utils";
import { I18n } from "react-redux-i18n";
import { closeAlert, openAlert } from "../../../redux/alert/alertSlice";

interface IProps {
  caller?: string;
  eventId?: number;
  value?: number;
  locked: boolean;
  code?: string;
  unique?: string;
  oddLabel?: string;
  marketLabel?: string;
  spreadLabel?: string | number;
  oddId?: number
  marketId?: number
  tournamentId?: number
}
function Odd({
  caller,
  value,
  locked,
  code,
  eventId,
  oddLabel,
  marketLabel,
  unique,
  spreadLabel,
  oddId,
  marketId,
  tournamentId
}: IProps): ReactElement {
  const [width] = useWindowSize()
  const dispatch = useAppDispatch();
  const odds = useAppSelector((state) => state.coupon.odds);
  const oddType = useAppSelector(state => state.config.oddsType)
  const coupon = useAppSelector(state => state.coupon.coupon)
  const sports = useAppSelector(state => state.sport.sports)
  const outrights = useAppSelector(state => state.sport.outrights)

  const [prevValue, setPrevValue] = useState<undefined | number>(undefined);
  const [prevUnique, setPrevUnique] = useState<undefined | string>(undefined);
  const [indicator, setIndicator] = useState<undefined | string>("none");
  const [oddsBiggestBinding, setOddsBiggestBinding] = useState<number>(0)
  const [convertedOddValue, setConvertedOddValue] = useState<string | undefined>(value?.toFixed(2));

  const handleBinding = (type: string) => {
    const doesOddsContainSport = odds.find(odd => odd.caller === "sport")
    const sportOddsLength = odds.filter(odd => odd.caller === "sport").length
    const currentOddBinding = eventId && recursiveSearch(eventId, sports)?.binding
    if (width <= 1250 && (currentOddBinding || doesOddsContainSport)) {
      const eventsLength = Object.keys(coupon).length
      const biggestBinding = currentOddBinding && currentOddBinding > oddsBiggestBinding ? currentOddBinding : oddsBiggestBinding
      switch (type) {
        case "remove":
          if ((eventsLength - 1 === 0 || sportOddsLength <= 1)) {
            dispatch(closeAlert())
          } else {
            dispatch(openAlert({
              isAutoHide: true,
              success: false,
              severity: "info",
              message: `${I18n.t("sport.bindingError1stPart")} ${biggestBinding - (eventsLength - 1)} ${I18n.t("sport.bindingError2ndPart")}`
            }))
          }
          break;
        case "add":
          if (eventsLength + 1 === biggestBinding) {
            dispatch(closeAlert())
          } else if (eventsLength === 0) {
            dispatch(openAlert({
              isAutoHide: true,
              success: false,
              severity: "info",
              message: `${I18n.t("sport.bindingError1stPart")}  ${biggestBinding - 1} ${I18n.t("sport.bindingError2ndPart")}`
            }))
          } else if (biggestBinding > eventsLength + 1) {
            dispatch(openAlert({
              isAutoHide: true,
              success: false,
              severity: "info",
              message: `${I18n.t("sport.bindingError1stPart")}  ${biggestBinding - (eventsLength + 1)} ${I18n.t("sport.bindingError2ndPart")}`
            }))
          }
          break;
        default:
          break;
      }
    }
  }



  const handleOddClick = () => {
    if (caller !== "coupon" && !locked) {
      if (unique && odds?.find(odd => odd.unique?.includes(unique))) {
        dispatch(
          removeOddFromCoupon({
            eventId: eventId,
            unique: unique,
          })
        );
        handleBinding("remove")
      } else {
        dispatch(
          addOddInCoupon({
            eventId: eventId,
            odd: {
              id: oddId,
              value: value,
              unique: unique,
              code: code,
              locked: locked,
              oddLabel: oddLabel,
              eventId: eventId,
              marketId: marketId,
              marketLabel: marketLabel,
              tournamentId: tournamentId,
              spreadLabel: spreadLabel,
              fix: false,
              caller: caller,
            },
          })
        );
        handleBinding("add")
      }
    }
  };

  useEffect(() => {
    odds.map(odd => {
      if (odd.caller === "sport" && odd.eventId) {
        let eventConfig;
        if (recursiveSearch(odd.eventId, sports)) {
          eventConfig = recursiveSearch(odd.eventId, sports)
        } else {
          eventConfig = recursiveSearch(odd.eventId, outrights)
        }
        if (eventConfig?.binding && eventConfig.binding > oddsBiggestBinding) {
          setOddsBiggestBinding(eventConfig.binding)
        }
      }
    })
  }, [odds])

  useEffect(() => {
    if (prevValue && value && (Number(prevValue) !== Number(value))) {
      if (Number(prevValue) < Number(value) && prevUnique == unique) {
        setIndicator("up");
      } else {
        prevUnique == unique &&
          setIndicator("down");
      }
      setPrevValue(value);
      setPrevUnique(unique);
    } else {
      value && setPrevValue(value);
      unique && setPrevUnique(unique);
    }
  }, [value, unique]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (indicator === "up" || indicator === "down") {
        setIndicator("none");
      }
    }, 4000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [indicator]);

  useEffect(() => {
    value && setConvertedOddValue(convertOddValue(value))
  }, [oddType, value])


  return (
    <Box
      onClick={handleOddClick}
      className={styles.oddContainer}
    >
      <Box
        className={
          `${caller !== "coupon" &&
            !locked && value
            ? styles.oddContent
            : styles.couponOddContent
          } ${caller !== "coupon" && unique && odds?.find((el: any) => el?.unique?.includes(unique))
            ? styles.clickedOdd
            : ""
          }${indicator === "up" ? styles.up : ""}
          ${indicator === "down" ? styles.down : ""}`}
      >
        {indicator === "up" &&
          <Box className={styles.upIndicator}>
            <FontAwesomeIcon name="caret-up" type="fas" />
          </Box>
        }
        {
          value ?
            locked ?
              <FontAwesomeIcon type="fas" name="lock-keyhole" />
              :
              convertedOddValue
            :

            <FontAwesomeIcon type="fas" name="lock-keyhole" />
        }
        {indicator === "down" &&
          <Box className={styles.downIndicator}>
            <FontAwesomeIcon name="caret-down" type="fas" />
          </Box>
        }
      </Box>
    </Box>
  );
}

export default Odd;
