import React, { ReactElement, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, Button, Typography } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import { verifyPlayedCouponRequested } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss"

interface IProps {
  caller?: string
}

export default function CouponVerify({ caller }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const lang = useAppSelector(state => state.auth.locale);
  const [couponId, setCouponId] = useState<string | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCouponId(event.target.value)
  }
  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      dispatch(verifyPlayedCouponRequested(couponId))
    }
  }
  const handleVerify = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(verifyPlayedCouponRequested(couponId))
  }
  return (
    <Box className={styles.verifyCpnContainer}>
      {!caller &&
        <Typography className={styles.verifyCpnLabel}>
          <Translate value="coupon.showVerifyMessage" />
        </Typography>
      }
      <form
        onSubmit={(event) => handleVerify(event)}
        className={styles.searchContainer}
      >
        <TextField
          required
          value={couponId}
          color="secondary"
          onChange={(event) => handleChange(event)}
          onKeyDown={(event) => handleEnter(event)}
          placeholder={
            caller ?
              lang === "it" ?
                "Cerca scommesse" :
                lang === "es" ?
                  "Busca apuestas" :
                  lang === "pt" ?
                    "Pesquisar Apostas" :
                    lang === "zh" ?
                      "搜索投注" :
                      lang === "fr" ?
                        "Rechercher des paris" :
                        lang === "de" ?
                          "Suchwetten" :
                          lang === "ar" ?
                            "بحث الرهانات" :
                            lang === "sq" ?
                              "Kërko baste"
                              : "Search Bets"
              :
              lang === "it" ?
                "Verifica codice" :
                lang === "es" ?
                  "Código de verificación" :
                  lang === "pt" ?
                    "Código código" :
                    lang === "zh" ?
                      "檢查代碼" :
                      lang === "fr" ?
                        "Vérifier le code" :
                        lang === "de" ?
                          "Code überprüfen" :
                          lang === "ar" ?
                            "التحقق من الشفرة" :
                            lang === "sq" ?
                              "Kontrollo kodin"
                              : "Check code"
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box className={styles.searchIcon}>
                  <FontAwesomeIcon name="magnifying-glass" type="fas" />
                </Box>
              </InputAdornment>
            )
          }}
          className="form-label"
        />
        {!caller &&
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            className={styles.verifyButton}
          >
            <Typography>
              <Translate value={"coupon.verify"} />
            </Typography>
          </Button>
        }
      </form>
    </Box>
  )
}