
import React, { ReactElement } from "react";
import { Translate } from "react-redux-i18n";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from "@mui/material";

import styles from "./styles.module.scss";

function CouponFetchingView(): ReactElement {
  return (
    <Box className={styles.container} >
      <CircularProgress
        color="secondary"
        size={20}
        className={styles.spinner} />
      <Typography className={styles.text}>
        <Translate value="coupon.isFetchingCoupon" />
      </Typography>
    </Box>
  );
}
export default CouponFetchingView;
