import React, { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Translate } from "react-redux-i18n";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { Typography } from "@mui/material";
import { Wallet, getChildrenRequested, saveBettingWalletId } from "../../../redux/wallet/walletSlice";
import styles from "./styles.module.scss";

interface IProps {
  selectedWallet: Wallet | null;
  setSelectedWallet: React.Dispatch<React.SetStateAction<Wallet | null>>;
  selectedWalletInput: string;
  setSelectedWalletInput: React.Dispatch<React.SetStateAction<string>>;
  type: string;
}

export default function WalletChildrenSelect({
  selectedWalletInput,
  setSelectedWalletInput,
  selectedWallet,
  setSelectedWallet,
  type
}: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const authWalletId = useAppSelector((state) => state.auth.authWallet?.id);
  const walletId = useAppSelector((state) => state.wallet.walletId);
  const bettingWalletChildren = useAppSelector((state) => state.wallet.bettingWalletChildren);
  const isFetchingMoveFound = useAppSelector((state) => state.wallet.isFetchingMoveFound);
  const [options, setOptions] = useState<Wallet[] | null | undefined>(undefined)


  const handleChange = (value: Wallet | null) => {
    if (value) {
      setSelectedWallet(value)
      dispatch(saveBettingWalletId(value.id))
    } else {
      setSelectedWallet(null)
    }

  }
  const handleInputChange = (value: string) => {
    setSelectedWalletInput(value)

  }
  useEffect(() => {
    if (selectedWallet === null) {
      if (type === "accountMenu") {
        authWalletId &&
          dispatch(
            getChildrenRequested({
              caller: "betting",
              type: "new",
              id: authWalletId
            }))

      } else {
        if (walletId && authWalletId && (walletId !== authWalletId) && !isFetchingMoveFound) {
          dispatch(
            getChildrenRequested({
              caller: "betting",
              type: "new",
              id: walletId,
            }))
        } else {
          if (authWalletId && !isFetchingMoveFound) {
            dispatch(
              getChildrenRequested({
                caller: "betting",
                type: "new",
                id: authWalletId
              }))
          }
        }
      }
    }


  }, [authWalletId, walletId, isFetchingMoveFound]);

  useEffect(() => {
    if (walletId && authWalletId && (walletId !== authWalletId)) {
      setOptions(bettingWalletChildren[walletId])
    } else {
      if (authWalletId) {
        setOptions(bettingWalletChildren[authWalletId])
      }
    }
  }, [bettingWalletChildren]);

  useEffect(() => {
    type === "coupon" &&
      selectedWallet === null &&
      options &&
      setSelectedWallet(options[0])
  }, [options]);


  return (
    <Autocomplete
      autoHighlight
      value={selectedWallet}
      onChange={(e, newValue) => handleChange(newValue)}
      inputValue={selectedWalletInput}
      onInputChange={(e, newValue) => handleInputChange(newValue)}
      options={options || []}
      getOptionLabel={(option) =>
        `${option.name}`.concat(` (${option.availability.toFixed(2)}${option.currency.symbol})`).charAt(0).toUpperCase() + `${option.name}`.concat(` (${option.availability.toFixed(2)}${option.currency.symbol})`).slice(1)
      }
      renderOption={(props, option) => (
        <Typography  {...props} className={styles.menuItem}>
          {
            `${option.name}`.concat(` (${option.availability.toFixed(2)}${option.currency.symbol})`).charAt(0).toUpperCase() + `${option.name}`.concat(` (${option.availability.toFixed(2)}${option.currency.symbol})`).slice(1)
          }
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          sx={{ outline: "none", boder: "none" }}
          {...params}
          variant="outlined"
          color="secondary"
          size="small"
          required={
            type === "coupon" ?
              true
              : type === "betting" || type === "accountMenu" ?
                false
                : type === "trx" ?
                  false
                  : true

          }
          label={
            type === "coupon" ?
              <Translate value="coupon.select" />
              : type === "betting" || type === "accountMenu" ?
                <Translate value="bettingFilter.usernameCombo" />
                : type === "trx" ?
                  <Translate value="bettingFilter.usernameCombo" />
                  : <Translate value="movefound.searchUsers" />

          }
          fullWidth
          className={[(type === "betting" || type === "trx") ? styles.textfield : styles.textfieldDefault, "form-label"].join(" ")}
        />
      )
      }
    />
  )
}