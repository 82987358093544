import React, { ReactElement } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import CloseIcon from '@mui/icons-material/Close';

import ConfirmProceed from "../ConfirmProceed";
import AuthenticationMessage from "../../appbar/AuthenticationMessage";
import Betcodes from "../../coupon/Betcodes"
import { closeDialog } from "../../../redux/dialog/dialogSlice";
import styles from "./styles.module.scss"
import { clearCashoutData } from "../../../redux/coupon/couponSlice";
import Cashout from "../Cashout";

function Modal(): ReactElement {
  const dispatch = useAppDispatch();
  const isDialogOpen = useAppSelector(state => state.dialog.isDialogOpen);
  const dialogContent = useAppSelector(state => state.dialog.dialogContent);
  const cashoutState = useAppSelector(state => state.coupon.cashoutState)

  const handleClose = () => {
    dispatch(closeDialog())
    dialogContent === "cashout" && dispatch(clearCashoutData())
  }
  return (
    <Dialog
      open={isDialogOpen}
      maxWidth={(dialogContent === "betcodes") ? "md" : false}
      fullWidth={dialogContent === "betcodes" && true}
      className={styles.dialog}
    >
      <DialogTitle className={styles.dialogTitle}>

        {dialogContent !== "confirmProceed" && cashoutState !== "confirm" &&
          <Box
            onClick={handleClose}
            className={styles.closeIcon}
          >
            <CloseIcon />
          </Box>
        }
      </DialogTitle>
      <DialogContent className={styles.dialogContainer} >
        {dialogContent === "login" &&
          <AuthenticationMessage />
        }
        {dialogContent === "confirmProceed" &&
          <ConfirmProceed />
        }
        {dialogContent === "betcodes" &&
          <Betcodes />
        }
        {
          dialogContent === "cashout" &&
          <Cashout />
        }
      </DialogContent>
    </Dialog>
  )
}
export default Modal;