import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface WalletState {
  isFetchingSkinBetting: boolean;
  isFetchingChildren: boolean;
  isFetchingChildrenUpdate: boolean;
  walletId?: number;
  bettingWalletChildren: Record<string, Wallet[]>;
  isFetchingMoveFound: boolean;
  bettingWalletId?: number;
  networkWallet?: Wallet;
}
export interface Currency {
  id: number;
  label: string;
  enabled: number;
  decimal: number;
  symbol: string;
  divider: number;
}
export interface Wallet {
  id: number;
  name: string;
  overdraft: number;
  balance: number;
  children_overdraft: number;
  children_balance: number;
  children_availability: number;
  children_count: number;
  enabled: boolean;
  currency_id: number;
  currency: Currency;
  parent_id: number;
  availability: number;
  wallet_type?: WalletType;
  address: string;
  city_id: number;
  fee: number;
  total: number;
  vat: string;
  cap: string;
  provigional_plan?: ProvidgionalType;
  wallet_type_id: number;
  campaign: boolean;
}
export interface WalletType {
  id?: number;
  label?: string;
  icon?: string;
  multiuser?: number;
  backoffice?: number;
  provigional_plan: boolean;
  can_contain_wallettype?: number[];
  can_perform_transactiontype?: number[];
  enabled?: boolean;
  permissions?: Array<string>;
  children: boolean;
}
export interface ProvidgionalType {
  id?: number;
  name?: string;
  label?: string;
}
export interface BetFilter {
  wallet_id: string;
  coupon_type_id: string | number;
  coupon_state_id: string | number;
  events_type_id: string;
  paid: string;
  date_type: string;
  start_date: string | Date;
  end_date: string | Date;
  start_amount: string | number;
  end_amount: string | number;
  serial: string | number;
  order: string;
}
export interface RequestPayloadBetting {
  id?: number;
  params?: Record<string, number | undefined | BetFilter>;
}
const initialState: WalletState = {
  isFetchingSkinBetting: false,
  isFetchingChildren: false,
  isFetchingChildrenUpdate: false,
  walletId: undefined,
  bettingWalletChildren: {},
  isFetchingMoveFound: false,
  bettingWalletId: undefined,
  networkWallet: undefined,
};
export interface IChildrenPayloadRequest {
  caller: string;
  type: string;
  id: number;
}

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    saveBettingWalletId: (
      state: WalletState,
      action: PayloadAction<number | undefined>
    ) => {
      state.bettingWalletId = action.payload
        ? action.payload
        : state.networkWallet?.id;
    },
    getSkinBettingRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadBetting>
    ) => {
      state.isFetchingSkinBetting = true;
      action;
    },
    getChildrenRequested: (
      state: WalletState,
      action: PayloadAction<IChildrenPayloadRequest>
    ) => {
      action.payload.type === "new"
        ? (state.isFetchingChildren = true)
        : (state.isFetchingChildrenUpdate = true);
    },
  },
});

export const {
  saveBettingWalletId,
  getSkinBettingRequested,
  getChildrenRequested,
} = walletSlice.actions;
export default walletSlice.reducer;
