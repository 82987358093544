import React, { ReactElement } from "react";
import { Box } from "@mui/material";

interface Props {
  name: string;
  type?: "fas" | "far" | "fal" | "fad" | "fab" | "fa";
  margin?: number
  width?: string
  color?: string,
  size?: string
}

FontAwesomeIcon.defaultProps = {
  type: "fas",
};

export default function FontAwesomeIcon(props: Props): ReactElement {
  return (
    <Box
      className={`${props.type} fa-${props.name} `}
      sx={{
        mr: props.margin ? props.margin : "",
        width: props.width,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        color: props.color,
        fontSize: props.size
      }} />);
}
