import React, { ReactElement, } from "react";
import { Box } from "@mui/material";

import FontAwesomeIcon from "../FontAwesomeIcon";
import styles from "./styles.module.scss";

function Hourglass(): ReactElement {
    return (
        <Box className={styles.hourglass}>
            <FontAwesomeIcon name="hourglass" type="fas" color="white" />
        </Box>
    )
}

export default Hourglass