import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import authReducer from "./auth/authSlice";
import walletReducer from "./wallet/walletSlice";
import liveReducer from "./live/liveSlice";
import sportReducer from "./sport/sportSlice";
import couponReducer from "./coupon/couponSlice";
import dialogReducer from "./dialog/dialogSlice";
import alertReducer from "./alert/alertSlice";
import configReducer from "./config/configSlice";

const rootReducer = combineReducers({
  i18n: i18nReducer,
  auth: authReducer,
  wallet: walletReducer,
  sport: sportReducer,
  live: liveReducer,
  coupon: couponReducer,
  dialog: dialogReducer,
  alert: alertReducer,
  config: configReducer,
});

export default rootReducer;
