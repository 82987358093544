import { Box } from "@mui/material";
import React, { ReactElement, SetStateAction } from "react";

import FontAwesomeIcon from "../FontAwesomeIcon";
import styles from "./styles.module.scss"

interface Checked {
  checked: boolean
  setChecked?: React.Dispatch<SetStateAction<boolean>>
  variant: string
}

interface IProps {
  couponCheck: Checked
}

function CheckBox({ couponCheck }: IProps): ReactElement {
  const { checked, setChecked, variant } = couponCheck
  return (
    <Box
      onClick={() => setChecked && setChecked(!checked)}
      className={`${styles.checkBoxContainer} 
    ${checked && styles.checkedBg} 
    ${variant === "s" ? styles.smallCheckbox : styles.normalCheckbox}`}
    >
      {checked && <Box className={styles.checkedIcon}>
        <FontAwesomeIcon name="check" type="fas" />
      </Box>}
    </Box>
  )
}

export default CheckBox