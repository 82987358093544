import React, { ReactElement, useEffect } from "react";

import CouponContent from "../../components/shared/CouponContent";
import { clearCouponResponse, verifyPlayedCouponRequested } from "../../redux/coupon/couponSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useParams } from "react-router-dom";
import setLanguage from "../../services/i18n";
import { clearAuthState, getAuthDetailsRequested, saveToken, setLang } from "../../redux/auth/authSlice";


export default function CouponRoute(): ReactElement {
  const dispatch = useAppDispatch()
  const { lang, serial, token } = useParams()

  useEffect(() => {
    lang && setLanguage(lang);
    lang && dispatch(setLang(lang));
    if (token) {
      dispatch(getAuthDetailsRequested());
    } else {
      dispatch(clearAuthState())
    }
    token && dispatch(saveToken(token))
  }, [token]);

  useEffect(() => {
    serial && dispatch(verifyPlayedCouponRequested({ serial: serial, caller: "route" }))

    return () => {
      dispatch(clearCouponResponse())
    }
  }, [serial])

  return (
    <CouponContent />
  )
}