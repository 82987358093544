import { store } from "../redux/store";
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";

export function setLanguage(language?: string): void {
  const defaultLanguage = navigator.language.substring(0, 2) || "en";
  store.dispatch(setLocale(language || defaultLanguage));
}

export function loadLanguage(): void {
  import(`../assets/i18n/translations.json`)
    .then((globalTranslation) => {
      syncTranslationWithStore(store);
      store.dispatch(loadTranslations(globalTranslation.default));
    })
    .catch((error: Error) => {
      throw new Error(error.message);
    });
}

export default setLanguage;
