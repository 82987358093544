
import React, { ReactElement, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, Typography } from "@mui/material";

import Odd from "../../shared/Odd";
import { IMarketData } from "../../../redux/sport/sportSlice";
import { saveFixedOdds, updateCalculationsForOddChange } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";

interface IProps {
  odd: any
}

function Odddiv({ odd }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const couponTypeTab = useAppSelector(state => state.coupon.couponTypeTab);
  /***********live ******************************************/
  const specificLiveMarket = useAppSelector(
    (state) =>
      odd.caller === "live" &&
      state.live.mainMarkets &&
      odd.eventId &&
      odd.marketId &&
      state.live.mainMarkets[odd.eventId] &&
      state.live.mainMarkets[odd.eventId][odd.marketId]);
  const specificLiveMarketOdds = specificLiveMarket && specificLiveMarket["odds"];
  /***********sport ******************************************/
  const eventMarkets = useAppSelector(state => state.sport.eventMarkets);
  const sportEventData = useAppSelector((state) =>
    odd.caller === "sport" &&
    odd.tournamentId &&
    (state.sport.tournaments[odd.tournamentId]?.events.find(event => event.id === odd.eventId) ||
      state.sport.sportWidget?.events?.find((event: any) => event.id === odd.eventId)));
  /****************************************************** */
  const fixedOdd = useAppSelector(
    state => state.coupon.fixedArr && state.coupon.fixedArr.find(el => el.unique === odd.unique) &&
      state.coupon?.fixedArr?.find(el => el.unique === odd.unique)?.fix);
  const [fixed, setFixed] = useState<boolean>(false)

  useEffect(() => {
    dispatch(updateCalculationsForOddChange())
  }, [odd.value])

  return (
    <>
      <Box className={styles.fixeddiv}>
        {couponTypeTab === 1 &&
          <Typography
            onClick={() => {
              setFixed(!fixed)
              odd.unique &&
                odd.code &&
                dispatch(saveFixedOdds({
                  fix: !fixed,
                  unique: odd.unique,
                  code: odd.code
                }))
            }

            }
            className={`${styles.fixed} ${fixedOdd && styles.fixedChecked}`}
          >
            F
          </Typography>}

      </Box>
      <Box className={styles.oddContainer}>
        <Odd
          value={
            odd.value
          }
          locked={
            odd.locked
          }
          caller="coupon"
        />
      </Box>

    </>
  )
}
export default Odddiv;