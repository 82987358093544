import React, { ReactElement } from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import {
  clearCouponResponse,
  showPlayedCouponReceipt,
  clearBookResponse,

} from "../../../redux/coupon/couponSlice";

import styles from './styles.module.scss'
import CouponContent from "../../shared/CouponContent";

interface IProps {
  skinLogo?: string
}

export default function PlayedCouponReceiptDialog({ skinLogo }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const isPlayedCouponReceiptVisible = useAppSelector(state => state.coupon.isPlayedCouponReceiptVisible);
  const isPlayedCouponDetailVisible = useAppSelector(state => state.coupon.isPlayedCouponDetailVisible);
  const playedCoupon = useAppSelector(state => state.coupon.playedCoupon);
  const isBooked = useAppSelector(state => state.coupon.isBooked)

  const handleClose = () => {
    dispatch(showPlayedCouponReceipt(false));
    !isPlayedCouponDetailVisible && dispatch(clearCouponResponse())

    if (isBooked) {
      dispatch(clearBookResponse())
    }
  }

  return (
    <Dialog
      open={isPlayedCouponReceiptVisible}
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth={"xs"}
    >
      <Box className={styles.mainContainer}>
        {(isBooked || (playedCoupon && !playedCoupon.user_confirm)) &&
          <DialogTitle sx={{ textAlign: "center" }} >
            <Grid container>
              <IconButton onClick={handleClose} sx={{ position: "absolute", top: 3, right: 12 }}>
                <FontAwesomeIcon type="fal" name="times" />
              </IconButton>
            </Grid>
          </DialogTitle >
        }
        <CouponContent />
      </Box>
    </Dialog >
  );
}
