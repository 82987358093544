
import React, { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import TextField from "@mui/material/TextField";
import { Box, Typography } from "@mui/material";

import { NUMBER_REGEXP } from "../../../constants";
import { saveCheckedGroup, saveCombinationAmount } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
import CheckBox from "../../shared/CheckBox";

interface IProps {
  caller: string;
  group?: number;
  nrComb?: number;
}

function GroupsGridRow({ caller, group, nrComb }: IProps): ReactElement {
  const dispatch = useAppDispatch()
  const coupon = useAppSelector(state => state.coupon.coupon)
  const combination = useAppSelector(state => state.coupon.combination)
  const combinationAmount = useAppSelector(state => state.coupon.combinationAmount)
  const checkedGroup = useAppSelector(state => group && state.coupon.checkedGroups[group])
  const maxOdd = useAppSelector(state => state.coupon.maxOdd)
  const minOdd = useAppSelector(state => state.coupon.minOdd)
  const [check, setCheck] = useState(false)

  const handleGroupCheck = () => {
    group &&
      nrComb &&
      dispatch(
        saveCheckedGroup({
          group: group,
          nrComb: nrComb
        }))
  }
  const handleCombAmountChange = (value: string) => {
    if (caller === "system") {
      dispatch(
        saveCombinationAmount({
          type: "system",
          data: {
            group: group,
            combinationAmount:
              (value && value.length > 0) ?
                value.replace(",", ".") : "",
            nrComb: nrComb
          }
        }
        ))
    } else {
      value &&
        dispatch(
          saveCombinationAmount({
            data: {
              combinationAmount:
                (value && value.length > 0) ?
                  value.replace(",", ".") : ""
            }
          }))
    }

  }
  useEffect(() => {
    if (checkedGroup) {
      setCheck(true)
    } else {
      setCheck(false)
    }
  }, [checkedGroup])

  return (
    <Box className={styles.gridRow}>
      <Box className={styles.container}>
        <Typography className={styles.containerItem}>
          {
            caller === "system" ?
              group
              :
              Object.keys(coupon).length
          }
        </Typography>
        {caller === "system" &&
          <CheckBox
            couponCheck={{
              checked: check,
              setChecked: handleGroupCheck,
              variant: "s"
            }}
          />
        }
      </Box>
      <Typography className={styles.gridRowItem}>
        {
          caller === "system" ?
            nrComb
            :
            combination
        }
      </Typography>
      <Box className={styles.gridRowItem}>
        <TextField
          name="combinationAmount"
          variant="outlined"
          value={
            caller === "system" ?
              checkedGroup &&
                checkedGroup.combinationAmount ?
                checkedGroup.combinationAmount
                : ""
              : combinationAmount ?
                combinationAmount
                : ""
          }
          fullWidth
          required
          onChange={(event) => {
            if (event.target.value === "" || NUMBER_REGEXP.test(event.target.value)) {
              handleCombAmountChange(event.target.value)
            }
          }}
          className={[styles.amountInput, "form-label"].join(" ")}
        />
      </Box>
      <Box className={styles.gridRowItemContent}>
        <Typography className={styles.gridRowItemContentItem}>
          {
            caller === "system" ?
              checkedGroup && checkedGroup.minPotentialWin ? checkedGroup.minPotentialWin.toFixed(2) : "0.00"
              :
              minOdd ? (minOdd * Number(combinationAmount)).toFixed(2) : "0.00"
          }
        </Typography>
        <Typography className={styles.gridRowItemContentItem}>
          {
            caller === "system" ?
              checkedGroup && checkedGroup.maxPotentialWin ? checkedGroup.maxPotentialWin.toFixed(2) : "0.00"
              :
              maxOdd ? (maxOdd * Number(combinationAmount)).toFixed(2) : "0.00"
          }
        </Typography>
      </Box>

    </Box>
  )
}
export default GroupsGridRow