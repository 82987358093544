import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletType } from "../wallet/walletSlice";

export interface AuthState {
  isAuth: boolean;
  isFetching: boolean;
  user?: User;
  authWallet?: Wallet;
  permissions?: string[];
  locale: string;
  token?: string;
  skin: any;
}
export interface ICurrentUser {
  username?: string;
  password: string;
}
export interface User {
  id?: number;
  username: string;
  email: string;
  fiscal_code: string;
  firstname: string;
  lastname: string;
  phone: string;
  enabled?: boolean;
  wallet_id?: number;
  display_name?: string;
  password: string;
  c_password: string;
}
export interface LoginResponse {
  wallet: Wallet;
  user: User;
  permissions: string[];
}

export interface Wallet {
  id: number;
  name: string;
  overdraft: number;
  balance: number;
  children_overdraft: number;
  children_balance: number;
  children_availability: number;
  children_count: number;
  enabled: boolean;
  currency_id: number;
  currency: Currency;
  parent_id: number;
  availability: number;
  wallet_type?: WalletType;
  wallet_type_id: number;
  address: string;
  city_id: number;
  fee: number;
  total: number;
  vat: string;
  cap: string;
}

export interface Currency {
  id: number;
  label: string;
  enabled: number;
  decimal: number;
  symbol: string;
  divider: number;
}
export interface CurrentUser {
  username?: string;
  password: string;
  c_password?: string;
  o_password?: string;
  referral?: number;
}
const initialState: AuthState = {
  isAuth: false,
  isFetching: false,
  user: undefined,
  locale: navigator.language.substring(0, 2) || "en",
  permissions: undefined,
  token: undefined,
  skin: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getAuthDetailsRequested: (state: AuthState) => {
      state.isFetching = true;
    },
    getAuthDetailsSucceded: (
      state: AuthState,
      action: PayloadAction<LoginResponse>
    ) => {
      const { user, wallet, permissions } = action.payload;
      state.isFetching = false;
      state.isAuth = true;
      state.user = user;
      state.authWallet = wallet;
      state.permissions = permissions;
    },
    getAuthDetailsFailed: (state: AuthState) => {
      state.isFetching = false;
      state.isAuth = false;
    },
    setLang: (state: AuthState, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    saveToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateWallet: (state: AuthState, action: PayloadAction<Wallet>) => {
      state.authWallet = action.payload;
    },
    clearAuthState: (state: AuthState) => {
      state.isFetching = false;
      state.isAuth = false;
      state.user = undefined;
      state.authWallet = undefined;
      state.permissions = undefined;
    },
    saveSkinData: (state: AuthState, action: PayloadAction<Wallet>) => {
      state.skin = action.payload;
    },
  },
});

export const {
  getAuthDetailsRequested,
  getAuthDetailsSucceded,
  getAuthDetailsFailed,
  setLang,
  saveToken,
  updateWallet,
  clearAuthState,
  saveSkinData,
} = authSlice.actions;

export default authSlice.reducer;
