import React, { ReactElement } from "react";
import Box from "@mui/material/Box";

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: IProps): ReactElement {
  const { children, value, index, ...other } = props;
  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </Box>
  );
}
export default TabPanel;