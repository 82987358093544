
import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import styles from "./styles.module.scss";

function JsonDisplay(): ReactElement {
  const betCodesData = useAppSelector(state => state.coupon.betCodesData)
  return (
    <Box className={styles.betcodesContainer}>
      <Box className={styles.headerTable}>
        <Typography className={styles.marketHeaderContainer}>Market</Typography>
        <Typography className={styles.shortcutHeaderContainer}>ShortCut</Typography>
        <Typography className={styles.selectionHeaderContainer}>Selection</Typography>
      </Box>

      {betCodesData.map((item: any, index) => (
        <Box key={index} className={styles.bodyTableContainer}>
          <Typography className={styles.marketBodyContainer}>{item.Market}</Typography>
          <Typography className={styles.shortcutBodyContainer}>{item.ShortCut}</Typography>
          <Typography className={styles.selectionBodyContainer}>{item.Selection}</Typography>
        </Box>
      ))}
    </Box>


  );
}

export default JsonDisplay;