import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { Drawer, Box } from "@mui/material";

import { toggleMobileCouponDrawer } from "../../../redux/coupon/couponSlice";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";

import styles from "./styles.module.scss"
import ContentTabs from "../../coupon/ContentTabs";
import { useWindowSize } from "../../../services/utils";

export default function CouponMobile(): ReactElement {
  const [width] = useWindowSize()
  const dispatch = useAppDispatch()
  const isCouponMobileVisible = useAppSelector(state => state.coupon.isCouponMobileVisible)
  return (
    <>
      {width <= 1250 &&
        <Drawer
          anchor="bottom"
          open={isCouponMobileVisible}
          onClose={() => dispatch(toggleMobileCouponDrawer(false))}
          className={styles.drawer}
        >
          <Box className={styles.closeCouponDrawerContainer}>
            <Box
              onClick={() => dispatch(toggleMobileCouponDrawer(false))}
              className={styles.closeCouponDrawer}
            >
              <FontAwesomeIcon name="xmark" type="fas" />
            </Box>
          </Box>
          <ContentTabs />
        </Drawer>
      }
    </>
  )
}