import React, { ReactElement, SetStateAction, useEffect, useState } from "react";
import { Translate } from "react-redux-i18n";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Paper from "@mui/material/Paper";

import CheckBox from "../../shared/CheckBox";
import WalletChildrenSelect from "../../wallet/WalletChildrenSelect";
import { Wallet } from "../../../redux/wallet/walletSlice";
import {
  bookCouponRequested,
  clearCouponState,
  saveBettingAmount,
  saveCouponRequestPayload,
  setBookState
} from "../../../redux/coupon/couponSlice";
import { openDialog } from "../../../redux/dialog/dialogSlice";
import { COUPON_AMOUNT_BUTTONS, IAmountButton, NUMBER_REGEXP } from "../../../constants";
import { checkWalletPermissions, recursiveSearch } from "../../../services/utils";
import styles from "./styles.module.scss";

interface IAmountLimitMsgError {
  isAmountLower: boolean,
  isCombinationAmountLower: boolean,
  isMaxColIntegralHigher: boolean,
  isMaxRowSystemHigher: boolean,
  isMaxRowIntegralHigher: boolean,
  isMaxRowMultipleHigher: boolean,
  limit?: number
}

interface IBindingErrorData {
  isBindingError: boolean;
  bindingEventsAmount?: number;
}

interface IUniqueErrorData {
  isUniqueError: boolean;
  uniqueEvents: any;
}

function SharedView(): ReactElement {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const permissions = useAppSelector((state) => state.auth.permissions);
  const skinCurrency = useAppSelector(state => state.auth.skin?.setting.symbol);
  const coupon = useAppSelector(state => state.coupon.coupon);
  const couponLength = useAppSelector(state => state.coupon.couponLength);
  const couponTypeTab = useAppSelector(state => state.coupon.couponTypeTab);
  const bettingAmount = useAppSelector(state => state.coupon.bettingAmount);
  const combination = useAppSelector((state) => state.coupon.combination);
  const maxColIntegral = useAppSelector(state => state.coupon.bettingConfig?.setting.max_col_integral);
  const maxRowIntegral = useAppSelector(state => state.coupon.bettingConfig?.setting.max_row_integral);
  const maxRowSystem = useAppSelector(state => state.coupon.bettingConfig?.setting.max_row_system);
  const maxRowMultiple = useAppSelector(state => state.coupon.bettingConfig?.setting.max_row_multiple);
  const nrEventsInCoupon = Object.keys(coupon).length;
  const [allowChangeValue, setAllowChangeValue] = useState(2);
  const [isTransferToChecked, setTransferToChecked] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [selectedWalletInput, setSelectedWalletInput] = useState<string>("");
  const [noWalletSelectedMsgError, setNoWalletSelectMsgError] = useState<boolean>(false);
  const [emptyAmountMsgError, setEmptyAmountMsgError] = useState(false);
  const [amountLimitMsgError, setAmountLimitMsgError] = useState<IAmountLimitMsgError>({
    isAmountLower: false,
    isCombinationAmountLower: false,
    isMaxColIntegralHigher: false,
    isMaxRowIntegralHigher: false,
    isMaxRowSystemHigher: false,
    isMaxRowMultipleHigher: false,
    limit: undefined
  });

  const [bindingError, setBindingError] = useState<IBindingErrorData>({
    isBindingError: false,
    bindingEventsAmount: undefined
  })

  const [uniqueError, setUniqueError] = useState<IUniqueErrorData>({
    isUniqueError: false,
    uniqueEvents: undefined
  })

  const handleClearAmount = () => {
    dispatch(saveBettingAmount(""))
    setEmptyAmountMsgError(false);
  }


  const handleAmountBtnClick = (button: IAmountButton) => {
    dispatch(saveBettingAmount((Number(bettingAmount) + button.value).toString()))
  }
  const handleRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowChangeValue(Number((event.target as HTMLInputElement).value));
  };

  useEffect(() => {
    if (maxColIntegral && combination && combination >= maxColIntegral) {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxColIntegralHigher: true,
        limit: maxColIntegral
      })
    } else {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxColIntegralHigher: false,
        limit: undefined
      })

    }
  }, [combination])

  useEffect(() => {
    if (
      couponLength > 1 &&
      (couponLength !== nrEventsInCoupon) &&
      maxRowIntegral &&
      (nrEventsInCoupon > maxRowIntegral)) {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxRowIntegralHigher: true,
        limit: maxRowIntegral
      })
    } else if (
      couponLength > 1 &&
      (couponLength === nrEventsInCoupon) &&
      maxRowMultiple &&
      (nrEventsInCoupon > maxRowMultiple) &&
      couponTypeTab === 0
    ) {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxRowMultipleHigher: true,
        limit: maxRowMultiple
      })
    } else if (
      couponLength > 1 &&
      (couponLength === nrEventsInCoupon) &&
      maxRowSystem &&
      (nrEventsInCoupon > maxRowSystem) &&
      couponTypeTab === 1
    ) {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxRowSystemHigher: true,
        limit: maxRowSystem
      })
    } else {
      setAmountLimitMsgError({
        ...amountLimitMsgError,
        isMaxRowIntegralHigher: false,
        isMaxRowMultipleHigher: false,
        isMaxRowSystemHigher: false,
        limit: undefined
      })
    }
  }, [nrEventsInCoupon])

  /** remove error msg if there is selected wallet */
  useEffect(() => {
    if (selectedWallet !== null) {
      setNoWalletSelectMsgError(false)
    }
  }, [selectedWallet])

  return (
    <Box className={styles.sharedViewcontainer}>
      {/* col/row/limit error msgs */}
      {
        Number(bettingAmount) === 0 &&
        emptyAmountMsgError &&
        <ErrorMsg type="emptyimport" />
      }
      {
        amountLimitMsgError.isAmountLower &&
        <ErrorMsg
          type="isAmountLower"
          limit={amountLimitMsgError.limit} />
      }
      {
        amountLimitMsgError.isCombinationAmountLower &&
        <ErrorMsg
          type="isCombinationAmountLower"
          limit={amountLimitMsgError.limit} />
      }
      {
        amountLimitMsgError.isMaxColIntegralHigher &&
        <ErrorMsg
          type="isMaxColIntegral"
          limit={amountLimitMsgError.limit}
        />
      }
      {
        (amountLimitMsgError.isMaxRowIntegralHigher ||
          amountLimitMsgError.isMaxRowMultipleHigher ||
          amountLimitMsgError.isMaxRowSystemHigher) &&
        <ErrorMsg
          type="isMaxRow"
          limit={amountLimitMsgError.limit}
        />
      }
      {/************* amount input field****/}
      <Box className={styles.amountsContainer}>
        <Box className={styles.amountInputContainer}>
          <Typography className={styles.selectionText}>
            {`Sel. ${couponLength}`}
          </Typography>
          <Typography className={styles.amountText}>
            <Translate value="coupon.import" />
          </Typography>
          <TextField
            name="bettingAmount"
            variant="outlined"
            value={bettingAmount ? bettingAmount : ""}
            fullWidth
            required
            onChange={(e) => {
              if (e.target.value === "" || NUMBER_REGEXP.test(e.target.value)) {
                dispatch(saveBettingAmount(e.target.value.replace(",", ".")))
              }
            }}
            className={[styles.amountInput, "form-label"].join(" ")}
          />
          <Box className={styles.clearBox}>
            <Button
              variant="contained"
              color="error"
              className={styles.clearButton}
              onClick={handleClearAmount}
            >
              <Translate value="coupon.clear" />
            </Button>
          </Box>
        </Box>
        {/*********** amounts btn***********/}
        <Box className={styles.amountButtonsContainer}>
          {
            COUPON_AMOUNT_BUTTONS.map(item =>
              <Button
                key={item.value}
                variant="contained"
                onClick={() => handleAmountBtnClick(item)}
                className={styles.amountButton}
              >
                {item.label}{skinCurrency}
              </Button>

            )
          }
        </Box>
      </Box>
      {/*********** info view***********/}
      {
        couponLength > 1 &&
          ((couponLength !== Object.keys(coupon).length) || couponTypeTab === 1) ?
          <IntegralSystemInfo />
          :
          <SingleMultipleInfo />
      }
      {/******allow change radio buttons*****/}
      <AllowChange
        allowChangeValue={allowChangeValue}
        handleRadioBtnChange={handleRadioBtnChange}
      />

      {/****transfet to checkbox && walletchildrenselect***/}
      {
        isAuth &&
        permissions
        && checkWalletPermissions(permissions, "game.betassist") &&
        <>
          <Box className={styles.transferToBox}>
            <CheckBox
              couponCheck={{
                checked: isTransferToChecked,
                setChecked: setTransferToChecked,
                variant: "s"
              }}
            />
            <Typography className={styles.transferToLabel}>
              <Translate value="coupon.transferOn" />
            </Typography>
          </Box>
          <Box className={styles.childrenSelectBox}>
            <WalletChildrenSelect
              selectedWallet={selectedWallet}
              setSelectedWallet={setSelectedWallet}
              selectedWalletInput={selectedWalletInput}
              setSelectedWalletInput={setSelectedWalletInput}
              type="coupon" />
          </Box>
        </>
      }
      {
        noWalletSelectedMsgError &&
        <ErrorMsg type="selectUser" />
      }
      {
        bindingError.isBindingError &&
        <BindingErrorMessage bindingValue={bindingError.bindingEventsAmount} setBindingError={setBindingError} />
      }
      {
        uniqueError.isUniqueError &&
        <UniqueErrorMessage uniqueError={uniqueError} setUniqueError={setUniqueError} />
      }
      <TotalWin />
      <ActionBtns
        selectedWallet={selectedWallet}
        allowChangeValue={allowChangeValue}
        isTransferToChecked={isTransferToChecked}
        setEmptyAmountMsgError={setEmptyAmountMsgError}
        setNoWalletSelectMsgError={setNoWalletSelectMsgError}
        amountLimitMsgError={amountLimitMsgError}
        setAmountLimitMsgError={setAmountLimitMsgError}
        setBindingError={setBindingError}
        setUniqueError={setUniqueError}
      />
    </Box >
  )
}

export default SharedView

function IntegralSystemInfo() {
  const bettingAmount = useAppSelector(state => state.coupon.bettingAmount);
  const maxBonus = useAppSelector(state => state.coupon.maxBonus);
  const minBonus = useAppSelector(state => state.coupon.minBonus);
  const maxOdd = useAppSelector(state => state.coupon.maxOdd);
  const minOdd = useAppSelector(state => state.coupon.minOdd);
  const maxPotentialWin = useAppSelector(state => state.coupon.maxPotentialWin);
  const minPotentialWin = useAppSelector(state => state.coupon.minPotentialWin);
  const couponTypeTab = useAppSelector(state => state.coupon.couponTypeTab)
  return (
    <Box className={styles.infoContainer}>
      {/*Col1*/}
      <Box className={styles.infoContainerColumn}>
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            <Translate value="coupon.totalMatches" />
          </Typography>
          <Typography className={styles.infoLabel}>
            {bettingAmount}
          </Typography>
        </Box>
        {couponTypeTab !== 1 &&
          <Box className={styles.infoContainerColumnItem}>
            <Typography className={styles.infoLabel}>
              <Translate value="coupon.minOdd" />
            </Typography>
            <Typography className={styles.numberLabel}>
              {minOdd && (minOdd !== null) ? minOdd.toFixed(2) : "0.00"}
            </Typography>
          </Box>
        }
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            Min Bonus
          </Typography>
          <Typography className={styles.numberLabel}>
            {minBonus ? minBonus.toFixed(2) : "0.00"}
          </Typography>
        </Box>
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            <Translate value={couponTypeTab !== 1 ? "coupon.minWin" : "coupon.minPotWin"} />
          </Typography>
          <Typography className={styles.numberLabel}>
            {minPotentialWin && (minPotentialWin !== null) ? minPotentialWin.toFixed(2) : "0.00"}
          </Typography>
        </Box>
      </Box>

      {/*Col2*/}
      <Box className={styles.infoContainerColumn}>
        <Box className={styles.infoContainerColumnItem} />
        {couponTypeTab !== 1 &&
          <Box className={styles.infoContainerColumnItem}>
            <Typography className={styles.infoLabel}>
              <Translate value="coupon.maxOdd" />
            </Typography>
            <Typography className={styles.numberLabel}>
              {maxOdd && (maxOdd !== null) ? maxOdd.toFixed(2) : "0.00"}
            </Typography>
          </Box>
        }
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            Max Bonus
          </Typography>
          <Typography className={styles.numberLabel}>
            {maxBonus ? maxBonus.toFixed(2) : "0.00"}
          </Typography>
        </Box>
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            <Translate value={couponTypeTab !== 1 ? "coupon.maxWin" : "coupon.maxPotWin"} />
          </Typography>
          <Typography className={styles.numberLabel}>
            {maxPotentialWin && (maxPotentialWin !== null) ? maxPotentialWin.toFixed(2) : "0.00"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
function SingleMultipleInfo() {
  const bettingAmount = useAppSelector(state => state.coupon.bettingAmount);
  const maxOdd = useAppSelector(state => state.coupon.maxOdd);
  const minBonus = useAppSelector(state => state.coupon.minBonus);
  return (
    <Box className={styles.infoContainer}>
      <Box className={styles.infoContainerColumn}>
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            <Translate value="coupon.totalMatches" />
          </Typography>
          <Typography className={styles.numberLabel}>
            {bettingAmount}
          </Typography>
        </Box>
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            <Translate value="coupon.totalOdd" />
          </Typography>
          <Typography className={styles.totalOddLabel}>
            {maxOdd && (maxOdd !== null) ? maxOdd.toFixed(2) : "0.00"}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.infoContainerColumn}>
        <Box className={styles.infoContainerColumnItem} />
        <Box className={styles.infoContainerColumnItem}>
          <Typography className={styles.infoLabel}>
            Bonus
          </Typography>
          <Typography className={styles.numberLabel}>
            {minBonus ? minBonus.toFixed(2) : "0.00"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
interface IAllowChangeProps {
  allowChangeValue: number,
  handleRadioBtnChange: (event: React.ChangeEvent<HTMLInputElement>) => void

}
function AllowChange({
  allowChangeValue,
  handleRadioBtnChange
}: IAllowChangeProps) {
  return (
    <FormControl className={styles.radiobuttonsContainer}>
      <RadioGroup
        value={allowChangeValue}
        onChange={handleRadioBtnChange}
      >
        <FormControlLabel
          value={2}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
              }}
              className={"radio-group"}
            />
          }
          label={
            <>
              <Typography className={styles.radiobuttonLabel} >
                <Translate value="coupon.acceptAllOdds" />
              </Typography>
              <Typography className={styles.radiobuttonLabelMin} >
                <Translate value="coupon.acceptAllOddsHint" />
              </Typography>
            </>
          }
          className={styles.formcontrolLabel}
        />
        <FormControlLabel
          value={1}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
              }}
              className={"radio-group"}
            />
          }
          label={
            <Typography className={styles.radiobuttonLabel} >
              <Translate value="coupon.acceptMaxOdds" />
            </Typography>
          }
          className={styles.formcontrolLabel}
        />
        <FormControlLabel
          value={0}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
              }}
              className={"radio-group"}
            />
          }
          label={
            <Typography className={styles.radiobuttonLabel} >
              <Translate value="coupon.noOddEdit" />
            </Typography>
          }
          className={styles.formcontrolLabel}
        />
      </RadioGroup>
    </FormControl>
  )
}
function TotalWin() {
  const potentialWinValue = useAppSelector(state => state.coupon.potentialWinValue);
  const isPotentialWinOverLimit = useAppSelector(state => state.coupon.isPotentialWinOverLimit);
  const skinCurrency = useAppSelector(state => state.auth.skin?.setting.symbol);
  return (
    <>
      {
        isPotentialWinOverLimit &&
        <ErrorMsg
          type="isPotentialWinOverLimit"
        />
      }
      <Typography className={styles.playedAmount}>
        {`${potentialWinValue ? potentialWinValue.toFixed(2) : "0.00"}`} {skinCurrency}
      </Typography>
    </>
  )
}
interface IActionBtnProps {
  selectedWallet: Wallet | null;
  allowChangeValue: number;
  setEmptyAmountMsgError: React.Dispatch<React.SetStateAction<boolean>>;
  setNoWalletSelectMsgError: React.Dispatch<React.SetStateAction<boolean>>
  amountLimitMsgError: IAmountLimitMsgError
  setAmountLimitMsgError: React.Dispatch<React.SetStateAction<IAmountLimitMsgError>>
  isTransferToChecked: boolean;
  setBindingError: React.Dispatch<React.SetStateAction<IBindingErrorData>>
  setUniqueError: React.Dispatch<React.SetStateAction<IUniqueErrorData>>
}
function ActionBtns({
  selectedWallet,
  allowChangeValue,
  setEmptyAmountMsgError,
  setNoWalletSelectMsgError,
  amountLimitMsgError,
  setAmountLimitMsgError,
  isTransferToChecked,
  setBindingError,
  setUniqueError
}: IActionBtnProps) {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.auth.isAuth)
  const bookState = useAppSelector(state => state.coupon.bookState);
  const permissions = useAppSelector((state) => state.auth.permissions);
  const proceedState = useAppSelector(state => state.coupon.proceedState);
  const bettingAmount = useAppSelector(state => state.coupon.bettingAmount);
  const combinationAmount = useAppSelector(state => state.coupon.combinationAmount);
  const odds = useAppSelector(state => state.coupon.odds);
  const settings = useAppSelector(state => state.coupon.bettingConfig?.setting);
  const checkedGroups = useAppSelector(state => state.coupon.checkedGroups);
  const fixedArr = useAppSelector(state => state.coupon.fixedArr);
  const couponType = useAppSelector(state => state.coupon.couponType);
  const proceedSetting = useAppSelector(state => state.coupon.bettingConfig?.setting.enabled)
  const keysInCheckedGroups = Object.keys(checkedGroups);
  const sports = useAppSelector(state => state.sport.sports)
  const outrights = useAppSelector(state => state.sport.outrights)
  const coupon = useAppSelector(state => state.coupon.coupon)
  const [doesCouponContainSport, setDoesCouponContainSport] = useState<boolean>(false)
  const [biggestOddBinding, setBiggestOddBinding] = useState<number>(0)

  const findBinding0 = () => {
    let hasBinding0;
    const allEvents: any[] = [];

    odds.map(odd => {
      const eventConfig = odd.eventId && recursiveSearch(odd.eventId, sports);
      eventConfig && allEvents.push(eventConfig)
    })
    if (allEvents.find(event => event.binding === 0)) {
      hasBinding0 = true;
    } else {
      hasBinding0 = false;
    }

    return ({ hasBinding0, allEvents: allEvents.filter(event => event.binding === 0) });
  }


  const handleCancel = () => {
    dispatch(clearCouponState())
  }
  const handleBook = () => {
    if (Number(bettingAmount) <= 0) {
      setEmptyAmountMsgError(true);
    } else if (permissions && checkWalletPermissions(permissions, "game.betassist") &&
      selectedWallet === null) {
      setNoWalletSelectMsgError(true)
    }
    else {
      if (bookState) {
        dispatch(setBookState(false))
        let systems: any = []
        keysInCheckedGroups.map(key => {
          const newItem = {
            group: Number(key),
            bet_amount: Number(checkedGroups[key].combinationAmount)
          }
          systems = [
            ...systems,
            newItem
          ]
        })
        dispatch(
          bookCouponRequested({
            odds: odds,
            bet_amount: Number(bettingAmount),
            allow_change: allowChangeValue,
            allow_transfer: isTransferToChecked,
            wallet_id:
              permissions &&
                checkWalletPermissions(permissions, "game.betassist") &&
                selectedWallet ?
                selectedWallet.id :
                undefined,
            systems: systems,
            fixed: fixedArr
          }))
      }
    }
  }
  const handleProceed = () => {

    let systems: any = []
    keysInCheckedGroups.map(key => {
      const newItem = {
        group: Number(key),
        bet_amount: Number(checkedGroups[key].combinationAmount)
      }
      systems = [
        ...systems,
        newItem
      ]
    })

    if (isAuth) {
      if (!doesCouponContainSport || (doesCouponContainSport && biggestOddBinding >= 0)) {
        if (findBinding0().hasBinding0 && Object.keys(coupon).length > 1) {
          setUniqueError({ isUniqueError: true, uniqueEvents: findBinding0().allEvents })
        } else {
          if (Object.keys(coupon).length >= biggestOddBinding) {
            if (Number(bettingAmount) === 0) {
              setEmptyAmountMsgError(true);
            } else if (
              permissions &&
              checkWalletPermissions(permissions, "game.betassist") &&
              selectedWallet === null
            ) {
              setNoWalletSelectMsgError(true)
            } else {
              switch (couponType) {
                case "win_live":
                  if (settings && (Number(bettingAmount) >= settings["bet_min_live"])) {
                    dispatch(openDialog({ type: "confirmProceed" }))
                  } else if (
                    settings &&
                    (Number(bettingAmount) > 0 &&
                      Number(bettingAmount) < settings["bet_min_live"])) {
                    setAmountLimitMsgError({
                      ...amountLimitMsgError,
                      isAmountLower: true,
                      limit: settings && settings["bet_min_live"]
                    })
                  }
                  break;
                case "win_integral":
                  if (
                    settings &&
                    (Number(bettingAmount) >= settings["bet_min_integral"]) &&
                    (Number(combinationAmount) >= settings["bet_col_integral"])) {
                    dispatch(openDialog({ type: "confirmProceed" }))
                  } else if (
                    settings &&
                    (Number(bettingAmount) > 0 &&
                      (Number(bettingAmount) < settings["bet_min_integral"] || Number(combinationAmount) < settings["bet_col_integral"]))) {
                    setAmountLimitMsgError({
                      ...amountLimitMsgError,
                      isCombinationAmountLower: true,
                      limit: settings && (Number(combinationAmount) < settings["bet_col_integral"]) ?
                        settings["bet_col_integral"]
                        : settings && (Number(bettingAmount) < settings["bet_min_integral"]) ?
                          settings["bet_min_integral"]
                          : undefined
                    })
                  }
                  break;
                case "win_system":
                  if (
                    settings &&
                    (Number(bettingAmount) >= settings["bet_min_system"]) &&
                    !systems.find((element: any) => element.bet_amount < settings["bet_col_system"])) {
                    dispatch(openDialog({ type: "confirmProceed" }))
                  } else if (
                    settings &&
                    (Number(bettingAmount) > 0 &&
                      (Number(bettingAmount) < settings["bet_min_system"] || Number(combinationAmount) < settings["bet_col_system"]))) {
                    setAmountLimitMsgError({
                      ...amountLimitMsgError,
                      isCombinationAmountLower: true,
                      limit: settings && systems.find((element: any) => element.bet_amount < settings["bet_col_system"]) ?
                        settings["bet_col_system"]
                        : settings && (Number(bettingAmount) < settings["bet_min_system"]) ?
                          settings["bet_min_system"]
                          : undefined
                    })
                  }
                  break;
                case "win_multiple":
                  if (settings && (Number(bettingAmount) >= settings["bet_min_multiple"])) {
                    dispatch(openDialog({ type: "confirmProceed" }))
                  } else if (
                    settings &&
                    (Number(bettingAmount) > 0 &&
                      Number(bettingAmount) < settings["bet_min_multiple"])
                  ) {
                    setAmountLimitMsgError({
                      ...amountLimitMsgError,
                      isAmountLower: true,
                      limit: settings && settings["bet_min_multiple"]
                    })
                  }
                  break;
                case "win_single":
                  if (settings &&
                    (Number(bettingAmount) >= settings["bet_min_single"])) {
                    dispatch(openDialog({ type: "confirmProceed" }))
                  } else if (settings &&
                    (Number(bettingAmount) > 0 && Number(bettingAmount) < settings["bet_min_single"])
                  ) {
                    setAmountLimitMsgError({
                      ...amountLimitMsgError,
                      isAmountLower: true,
                      limit: settings && settings["bet_min_single"]
                    })
                  }
                  break;
              }
              dispatch(
                saveCouponRequestPayload({
                  allow_change: allowChangeValue,
                  allow_transfer: isTransferToChecked,
                  bet_amount: Number(bettingAmount),
                  wallet_id:
                    permissions &&
                      checkWalletPermissions(permissions, "game.betassist") &&
                      selectedWallet !== null ?
                      selectedWallet.id
                      : undefined,
                }))
            }
          } else {
            setBindingError({ isBindingError: true, bindingEventsAmount: biggestOddBinding })
          }
        }
      }
    } else {
      dispatch(openDialog({ type: "login" }))
    }
  }

  useEffect(() => {
    const sportOdd = odds.find(odd => odd.caller === "sport")
    if (sportOdd) {
      setDoesCouponContainSport(true)
      odds.map(odd => {
        if (odd.caller === "sport" && odd.eventId) {
          let eventConfig;
          if (recursiveSearch(odd.eventId, sports)) {
            eventConfig = recursiveSearch(odd.eventId, sports)
          } else {
            eventConfig = recursiveSearch(odd.eventId, outrights)
          }
          if (eventConfig?.binding && eventConfig.binding > biggestOddBinding) {
            setBiggestOddBinding(eventConfig.binding)
          }
        }
      })
    } else {
      setDoesCouponContainSport(false)
      setBiggestOddBinding(0)
    }
  }, [odds])

  return (
    <Box className={styles.btncontainer}>
      <Button
        id="cancel"
        color="error"
        variant="contained"
        onClick={handleCancel}
        className={styles.cancelBtn}
      >
        <Translate value="coupon.cancel" />
      </Button>
      <Button
        id="book"
        color="warning"
        variant="contained"
        disabled={((settings && couponType && settings[couponType] > 0) || bookState) ? false : true}
        onClick={handleBook}
        className={settings && couponType && settings[couponType] === 0 ? styles.buttonDisabled : styles.bookBtn}
      >
        <Translate value="coupon.book" />
      </Button>
      <Button
        variant="contained"
        color="success"
        disabled={
          proceedSetting === 0 ||
            (!proceedState ||
              settings && couponType && settings[couponType] === 0 ||
              (isAuth &&
                permissions &&
                !(checkWalletPermissions(permissions, "game.bet") || checkWalletPermissions(permissions, "game.betassist"))))
            ?
            true
            :
            false
        }
        onClick={handleProceed}
        className={
          proceedSetting === 0 ||
            (!proceedState ||
              settings && couponType && settings[couponType] === 0 ||
              (isAuth &&
                permissions &&
                !(checkWalletPermissions(permissions, "game.bet") || checkWalletPermissions(permissions, "game.betassist"))))
            ?
            styles.buttonDisabled
            :
            styles.proceedBtn
        }
      >
        <Translate value="coupon.proceed" />
      </Button>
    </Box>
  )
}
interface IErrorMsg {
  type: string,
  limit?: number

}
function ErrorMsg({
  type,
  limit
}: IErrorMsg) {
  return (
    <Paper
      elevation={3}
      className={styles.importValueMessage}
    >
      <Typography className={styles.emptyImportMessage}>
        <Translate value={`coupon.${type}`} />
        {limit ? `${limit}!` : ""}
      </Typography>
    </Paper>
  )

}

interface IBindingErrorMessage {
  bindingValue?: number;
  setBindingError: React.Dispatch<SetStateAction<IBindingErrorData>>
}

function BindingErrorMessage({ bindingValue, setBindingError }: IBindingErrorMessage): ReactElement {
  const coupon = useAppSelector(state => state.coupon.coupon)
  const eventsLength = Object.keys(coupon).length

  useEffect(() => {
    setBindingError && setTimeout(() => {
      setBindingError({
        isBindingError: false,
        bindingEventsAmount: undefined,
      })
    }, 5000);
  }, [])

  useEffect(() => {
    if (bindingValue && eventsLength >= bindingValue) {
      setBindingError({
        isBindingError: false,
        bindingEventsAmount: undefined,
      })
    }
  }, [eventsLength])

  return (
    <Paper
      elevation={3}
      className={styles.importValueMessage}
    >
      <Typography className={styles.emptyImportMessage}>
        <Translate value="sport.bindingError1stPart" /> {`${bindingValue && bindingValue - eventsLength}`} <Translate value="sport.bindingError2ndPart" />.
      </Typography>
    </Paper>
  )
}

interface IUniqueErrorMessage {
  uniqueError: IUniqueErrorData
  setUniqueError: React.Dispatch<SetStateAction<IUniqueErrorData>>
}

function UniqueErrorMessage({ setUniqueError, uniqueError }: IUniqueErrorMessage) {
  const uniqueBindingEvent = uniqueError.uniqueEvents[0]
  useEffect(() => {
    setUniqueError && setTimeout(() => {
      setUniqueError({ isUniqueError: false, uniqueEvents: undefined })
    }, 5000);
  }, [])

  return (
    <Paper
      elevation={3}
      className={styles.importValueMessage}
    >
      <Typography className={styles.emptyImportMessage}>
        {uniqueBindingEvent?.home} {uniqueBindingEvent?.away.length > 0 && "-"} {uniqueBindingEvent?.away}  <Translate value="sport.uniqueError2nd" />
      </Typography>
    </Paper>
  )
}