import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { instanceAxios } from "../../services/axiosServices";
import {
  getCalendarDataFailed,
  getCalendarDataRequested,
  getCalendarDataSucceded,
  getEventSnapshotFailed,
  getEventSnapshotRequested,
  getEventSnapshotSucceded,
  getSnapshotFailed,
  getSnapshotRequested,
  getSnapshotSucceded,
  getVideoFailed,
  getVideoRequested,
  getVideoSucceded,
} from "./liveSlice";

export function* getSnapshot(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/live/snapshot");
    const { data } = response.data;
    yield put(getSnapshotSucceded(JSON.stringify(data)));
  } catch (error) {
    yield put(getSnapshotFailed());
  }
}

export function* getCalendarData(): unknown {
  try {
    const respose = yield call(instanceAxios.get, "/api/live/calendar");
    const { data } = respose.data;
    yield put(getCalendarDataSucceded(data));
  } catch (error) {
    yield put(getCalendarDataFailed());
  }
}

export function* getEventSnapshot({
  payload,
}: ReturnType<typeof getEventSnapshotRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/live/event/${payload}`
    );
    const { data } = response.data;
    yield put(getEventSnapshotSucceded(JSON.stringify(data)));
  } catch (error) {
    yield put(getEventSnapshotFailed());
  }
}

export function* getVideo({
  payload,
}: ReturnType<typeof getVideoRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/live/video/${payload}`
    );
    const { data } = response.data;
    yield put(getVideoSucceded(data.content));
  } catch (error) {
    yield put(getVideoFailed());
  }
}

export function* ongetSnapshotRequested(): Generator<unknown, void, unknown> {
  yield takeEvery(getSnapshotRequested, getSnapshot);
}
export function* ongetEventSnapshotRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeEvery(getEventSnapshotRequested, getEventSnapshot);
}

export function* onGetCalendarDataRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getCalendarDataRequested, getCalendarData);
}

export function* ongetVideoRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getVideoRequested, getVideo);
}

export function* liveSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(onGetCalendarDataRequested),
    call(ongetSnapshotRequested),
    call(ongetEventSnapshotRequested),
    call(ongetVideoRequested),
  ]);
}
