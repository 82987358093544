import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import Badge from '@mui/material/Badge';
import { Box, Typography } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import Coupon from "../Coupon";
import QuickBet from "../QuickBet";
import MyBets from "../MyBets";
import TabPanel from "../../shared/TabPanel";
import { checkWalletPermissions } from "../../../services/utils";
import { getMyBetsRequested, saveCouponTab } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
import OddType from "../../shared/OddType";

function ContentTabs(): ReactElement {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const permissions = useAppSelector((state) => state.auth.permissions);
  const couponLength = useAppSelector(state => state.coupon.couponLength);
  const couponTab = useAppSelector(state => state.coupon.couponTab);
  const playedBetsLength = useAppSelector(state => state.coupon.playedBets?.length);
  const playedBets = useAppSelector(state => state.coupon.playedBets);

  const handleChange = (newValue: number) => {
    dispatch(saveCouponTab(newValue));
  };

  useEffect(() => {
    isAuth &&
      permissions &&
      !playedBets &&
      (checkWalletPermissions(permissions, "game.bet") ||
        checkWalletPermissions(permissions, "game.betassist")) &&
      dispatch(getMyBetsRequested());
  }, [isAuth, permissions, playedBets])

  return (
    <Box className={styles.content}>
      <OddType />
      {
        isAuth &&
        permissions &&
        (checkWalletPermissions(permissions, "game.bet")
          || checkWalletPermissions(permissions, "game.betassist")) &&
        <QuickBet />
      }
      <Box className={styles.tabs}>
        {/* coupon badge */}
        <Button
          variant="contained"
          startIcon={
            <Badge
              badgeContent={couponLength}
              color="secondary"
              className={styles.iconStylediv}
            >
              <FontAwesomeIcon type="fal" name="ballot-check" margin={0.5} />
            </Badge>
          }
          onClick={() => handleChange(0)}
          className={couponTab === 0 ? styles.tabButtonActive : styles.tabButton}
        >
          <Typography className={styles.couponLabel}>
            <Translate value="coupon.coupon" />
          </Typography>
        </Button>
        {/* mybets badge */}
        {
          isAuth &&
          permissions &&
          (checkWalletPermissions(permissions, "game.betassist") ||
            checkWalletPermissions(permissions, "game.bet")) &&
          <Button
            variant="contained"
            startIcon={
              <Badge
                badgeContent={playedBetsLength}
                color="secondary"
                className={styles.iconStylediv}>
                <FontAwesomeIcon type="fal" name="ticket" margin={0.5} />
              </Badge>}
            onClick={() => handleChange(1)}
            className={couponTab === 1 ? styles.tabButtonActive : styles.tabButton}
          >
            <Translate value="coupon.myBets" />

          </Button>
        }
      </Box>
      {/* coupon tab */}
      <TabPanel
        value={couponTab}
        index={0}
      >
        <Coupon />
      </TabPanel>
      {/* mybets tab */}
      {
        isAuth &&
        permissions &&
        (checkWalletPermissions(permissions, "game.betassist") ||
          checkWalletPermissions(permissions, "game.bet")) &&
        <TabPanel
          value={couponTab}
          index={1}
        >
          <MyBets />
        </TabPanel>
      }
    </Box>
  );
}

export default ContentTabs;

