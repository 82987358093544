import React, { ReactElement } from "react";
import { Translate } from "react-redux-i18n";
import { Box, Typography } from "@mui/material";

import styles from "./styles.module.scss";

function GroupsGridHeader(): ReactElement {
  return (
    <Box className={styles.gridHeader}>
      <Typography className={styles.gridHeaderItem}>Grp.</Typography>
      <Typography className={styles.gridHeaderItem}>Com.</Typography>
      <Typography className={styles.gridHeaderItem}>
        <Translate value="coupon.import" />
      </Typography>
      <Typography className={styles.gridHeaderItem}>
        <Translate value="coupon.odd" />
      </Typography>
    </Box>
  )
}
export default GroupsGridHeader