import React, { ReactElement } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { Box } from "@mui/material";

import GroupsGridRow from "../GroupsGridRow";
import GroupsGridHeader from "../GroupsGridHeader";
import styles from "./styles.module.scss";

function GroupsGrid(): ReactElement {
  const coupon = useAppSelector(state => state.coupon.coupon);
  const couponLength = useAppSelector(state => state.coupon.couponLength);
  const couponTypeTab = useAppSelector(state => state.coupon.couponTypeTab);
  const groupComb = useAppSelector(state => state.coupon.groupComb)
  const maxColSystem = useAppSelector(state => state.coupon.bettingConfig?.setting["max_col_system"])

  return (
    <Box className={couponTypeTab === 0 ? `${styles.gridContainer}` : `${styles.gridContainerSystem}`} >
      {
        (couponLength > 1 &&
          (couponLength !== Object.keys(coupon).length) ||
          couponTypeTab === 1) &&
        < GroupsGridHeader />
      }
      {/**************integral ************/}
      {couponLength > 1 &&
        (couponLength !== Object.keys(coupon).length) &&
        <Box className={styles.gridRowIntegral}>
          <GroupsGridRow caller="integral" />
        </Box>

      }
      {/**************system ************/}
      {couponTypeTab === 1 &&
        <Box className={styles.gridRowContainer}>
          {
            Object.keys(groupComb).map(key => {
              if (maxColSystem && groupComb[key].nrComb < maxColSystem) {
                return (
                  <GroupsGridRow
                    key={key}
                    caller="system"
                    group={Number(key)}
                    nrComb={groupComb[key].nrComb}
                  />
                )
              }

            })
          }
        </Box>
      }
    </Box>
  )
}

export default GroupsGrid

