
import React, { ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from "@mui/material";

import {
  PlayedCouponState,
  checkCouponStateRequested
}
  from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
interface IProps {
  couponState: PlayedCouponState
}

function CouponInValidationView({ couponState }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (couponState.acceptance) {
      dispatch(checkCouponStateRequested(couponState.id))
      const checkInterval = setInterval(() => {
        dispatch(checkCouponStateRequested(couponState.id))
      }, 4000);
      return () => {
        clearInterval(checkInterval);
      }
    }
  }, [couponState.acceptance])

  return (
    <Box className={styles.container}>
      <CircularProgress
        color="secondary"
        size={20}
        className={styles.spinner}
      />
      <Typography className={styles.text}>
        <Translate value="coupon.acceptanceFirst" />
        &nbsp;{couponState?.serial}&nbsp;
        <Translate value="coupon.acceptanceSecond" />
      </Typography>
    </Box>
  );
}

export default CouponInValidationView;
