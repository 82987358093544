
import React, { ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import { PlayedCouponState, clearPlayResponse, updatePlayedCuponStates } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
import { CLEAR_VALIDATED_COUPON_MIN } from "../../../constants";

interface IProps {
  couponState: PlayedCouponState
}

function CouponRefusedView({ couponState }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const handleRemoveCoupon = () => {
    dispatch(updatePlayedCuponStates(couponState.id))
    dispatch(clearPlayResponse())
  }

  useEffect(() => {
    if (!couponState.acceptance) {
      const checkInterval = setInterval(() => {
        dispatch(updatePlayedCuponStates(couponState.id))
      }, CLEAR_VALIDATED_COUPON_MIN * 60 * 1000);
      return () => {
        clearInterval(checkInterval);
      }
    }
  }, [couponState.acceptance])

  return (
    <Box className={styles.container} >
      <Box className={styles.textdiv}>
        <FontAwesomeIcon
          type="far"
          name="info-circle"
          margin={1}
          color="white"
        />
        <Typography className={styles.text}>
          <Translate value="coupon.refusedFirst" />
          &nbsp;{couponState?.serial}&nbsp;
          <Translate value="coupon.refusedSecond" />
        </Typography>
      </Box>
      <Box className={styles.btncontainer}>
        <Button
          color="error"
          variant="contained"
          startIcon={<FontAwesomeIcon type="fal" name="close" />}
          onClick={handleRemoveCoupon}
          className={styles.button}
        >
          <Translate value="coupon.close" />
        </Button>
      </Box>

    </Box>

  );
}

export default CouponRefusedView;
