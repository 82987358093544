import React, { ReactElement } from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import MuiAlert from "@mui/material/Alert";
import Snackbar from '@mui/material/Snackbar';

import { closeAlert } from "../../../redux/alert/alertSlice";

export default function Toast(): ReactElement {
  const dispatch = useAppDispatch();
  const visible = useAppSelector((state) => state.alert.visible);
  const alertMessage = useAppSelector((state) => state.alert.message);
  const messageSeverity = useAppSelector((state) => state.alert.severity);
  const isAutoHide = useAppSelector((state) => state.alert.isAutoHide);

  const severity =
    messageSeverity === "error"
      ? "error"
      : messageSeverity === "info"
        ? "info"
        : messageSeverity === "warning"
          ? "warning"
          : "success";

  const handleClose = () => {
    dispatch(closeAlert());
  };

  return (
    <Snackbar
      open={visible}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={isAutoHide ? 2500 : null}
      onClose={handleClose}
    >
      <MuiAlert onClose={handleClose} severity={severity}>
        {
          Array.isArray(alertMessage) ?
            alertMessage.map(msg => {
              return (
                <p key={msg}>
                  {msg}
                </p>
              )
            })
            :
            alertMessage
        }
      </MuiAlert>
    </Snackbar>
  );
}
