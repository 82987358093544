import axios from "axios";
import { store } from "../redux/store";
import { REACT_APP_LANGUAGE_OPTIONS } from "../constants";
import { openAlert } from "../redux/alert/alertSlice";
import { clearAuthState } from "../redux/auth/authSlice";

export const instanceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_PROTO}${process.env.REACT_APP_BACKEND_URL}`,
});

instanceAxios.interceptors.request.use(
  (request: any) => {
    /**get the labels of languages in config */
    const langConfig = JSON.stringify(REACT_APP_LANGUAGE_OPTIONS);
    const jsonConfig = langConfig && JSON.parse(langConfig);
    const lang = jsonConfig.find(
      (el: Record<string, string>) => el.locale === store.getState().auth.locale
    ).header;
    request.headers = {
      "Skin-Language": lang,
      Accept: "application/json",
    };
    if (
      request.url !== "/api/auth/login" &&
      request.url !== "/api/auth/register"
    ) {
      const token = store.getState().auth.token;
      request.headers = {
        ...{ Authorization: `Bearer ` + token },
        "Skin-Language": lang,
        Accept: "application/json",
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err: any) => {
    const { response } = err;
    const { data } = response;
    const { error } = data;
    const errorArray: string[] = [];
    if (typeof error === "object") {
      for (const property in error) {
        errorArray.push(error[property].join());
      }
    }
    response.status === 401 && store.dispatch(clearAuthState());
    store.dispatch(
      openAlert({
        message: errorArray.length ? errorArray : error,
        severity: "error",
        success: false,
        isAutoHide: false,
      })
    );

    return Promise.reject(error);
  }
);
