import { instanceAxios } from "../../services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAuthDetailsRequested,
  getAuthDetailsSucceded,
  getAuthDetailsFailed,
} from "./authSlice";

export function* getAuthDetails(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/auth/detail");
    const { data } = response.data;
    yield put(getAuthDetailsSucceded(data));
  } catch (error) {
    yield put(getAuthDetailsFailed());
  }
}
export function* ongetAuthDetailsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getAuthDetailsRequested, getAuthDetails);
}
export function* authSagas(): Generator<unknown, void, unknown> {
  yield all([call(ongetAuthDetailsRequested)]);
}
