import { configureStore } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import rootReducer from "./reducers";

import storageSession from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth", "navigation", "coupon", "wallet", "config"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        // Ignore state paths, e.g. state for 'items':
        ignoredPaths: [
          "sport.groupsConfig",
          "sport.marketsConfig",
          "sport.sports",
          "sport.outrights",
          "sport.favourites",
          "sport.filteredSportsByTime",
          "live.palimpsest",
          "live.marketsConfig",
          "live.events",
          "live.markets",
          "live.composedSports",
          "live.composedCategories",
          "live.composedTournaments",
          "live.orderedSports",
          "live.orderedCategories",
          "live.orderedTournaments",
          "coupon.coupon",
          "coupon.odds",
        ],
      },
      serializableCheck: {
        ignoredPaths: [
          "sport.groupsConfig",
          "sport.marketsConfig",
          "sport.sports",
          "sport.outrights",
          "sport.favourites",
          "sport.filteredSportsByTime",
          "live.palimpsest",
          "live.marketsConfig",
          "live.events",
          "live.markets",
          "live.composedSports",
          "live.composedCategories",
          "live.composedTournaments",
          "live.orderedSports",
          "live.orderedCategories",
          "live.orderedTournaments",
          "coupon.coupon",
          "coupon.odds",
        ],
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const exported = { store, persistor };

export default exported;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
