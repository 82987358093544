import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, Button, Typography } from "@mui/material";
import { Translate } from "react-redux-i18n";

import FontAwesomeIcon from "../FontAwesomeIcon";
import { closeDialog } from "../../../redux/dialog/dialogSlice";
import {
  playCouponRequested,
  setProceedState,
  triggerBackdropVisibility
} from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss"

function ConfirmProceed(): ReactElement {
  const dispatch = useAppDispatch();
  const couponType = useAppSelector(state => state.coupon.couponType)
  const bettingLiveTimeout = useAppSelector(state => state.coupon.bettingConfig?.setting.bet_timeout_live)
  const odds = useAppSelector(state => state.coupon.odds);
  const couponRequestPayload = useAppSelector(state => state.coupon.couponRequestPayload)

  const checkedGroups = useAppSelector(state => state.coupon.checkedGroups);
  const keysInCheckedGroups = Object.keys(checkedGroups);
  const fixedArr = useAppSelector(state => state.coupon.fixedArr);

  const handleCancel = () => {
    dispatch(closeDialog())
  }

  const handleProceed = () => {
    const epochTimeStamp = Date.now()
    dispatch(closeDialog())
    dispatch(setProceedState(false))
    let systems: any = []
    keysInCheckedGroups.map(key => {
      const newItem = {
        group: Number(key),
        bet_amount: Number(checkedGroups[key].combinationAmount)
      }
      systems = [
        ...systems,
        newItem
      ]
    })
    if (couponType === "win_live" || (couponType !== "win_live" && (odds.find(el => el.caller === "live")))) {
      dispatch(triggerBackdropVisibility(true))
      setTimeout(() => {
        couponRequestPayload &&
          dispatch(
            playCouponRequested({
              ...couponRequestPayload,
              odds: odds,
              systems: systems,
              fixed: fixedArr,
              ts: epochTimeStamp
            }))
        dispatch(triggerBackdropVisibility(false))
      }, bettingLiveTimeout && bettingLiveTimeout * 1000)
    } else {
      couponRequestPayload &&
        dispatch(
          playCouponRequested({
            ...couponRequestPayload,
            odds: odds,
            systems: systems,
            fixed: fixedArr,
            ts: epochTimeStamp
          }))
    }
  }

  return (
    <>
      <Box className={styles.betPopupTitle}>
        <Typography className={styles.infoicon}>
          <FontAwesomeIcon type="fas" name="circle-info" margin={1} />
        </Typography>
        <Box className={styles.title}>
          <Translate value="coupon.message" />
        </Box>
      </Box>
      <Box className={styles.btnContainer}>
        <Button
          color="error"
          variant="contained"
          onClick={handleCancel}
          startIcon={
            <FontAwesomeIcon
              type="fal"
              name="square-xmark"
              width="15px"
            />
          }
          className={styles.button}
        >
          <Translate value="coupon.cancel" />
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleProceed}
          startIcon={
            <FontAwesomeIcon
              type="fal"
              name="square-check"
              width="15px"
            />
          }
          className={styles.button}
        >
          <Box>
            <Translate value="coupon.proceed" />
          </Box>
        </Button>
      </Box>
    </>
  )
}

export default ConfirmProceed