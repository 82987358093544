
import React, { ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import FontAwesomeIcon from "../../../components/shared/FontAwesomeIcon";
import {
  PlayedCouponState,
  showPlayedCouponReceipt,
  getPlayedCouponRequested,
  checkCouponStateRequested
} from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
interface IProps {
  couponState: PlayedCouponState
}

function CouponReproposeView({ couponState }: IProps): ReactElement {
  const dispatch = useAppDispatch();

  const handleAction = () => {
    dispatch(showPlayedCouponReceipt(true))
    dispatch(
      getPlayedCouponRequested({
        isRepeated: false,
        couponId: couponState.id
      }))
  }

  useEffect(() => {
    if (couponState.acceptance) {
      dispatch(checkCouponStateRequested(couponState.id))
      const checkInterval = setInterval(() => {
        dispatch(checkCouponStateRequested(couponState.id))
      }, 4000);
      return () => {
        clearInterval(checkInterval);
      }
    }
  }, [couponState.acceptance])
  return (
    <Box className={styles.container}>
      <Box className={styles.textdiv}>
        <FontAwesomeIcon
          type="far"
          name="info-circle"
          margin={1}
          color="white"
        />
        <Typography className={styles.text}>
          <Translate value="coupon.riproposedFirst" />
          &nbsp;{couponState?.serial}&nbsp;
          <Translate value="coupon.riproposedSecond" />
        </Typography>
      </Box>
      <Box className={styles.btncontainer}>
        <Button
          color="success"
          variant="contained"
          startIcon={<FontAwesomeIcon type="fal" name="comments-question-check" />}
          onClick={handleAction}
          className={styles.button}
        >
          <Translate value="coupon.view" />
        </Button>
      </Box>

    </Box>
  );
}

export default CouponReproposeView;
