export interface IAmountButton {
  label: string;
  value: number;
}

export const LIVE_FILTERS = [
  {
    value: "competition",
  },
  {
    value: "time",
  },
];

export const COUPON_AMOUNT_BUTTONS: IAmountButton[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "50",
    value: 50,
  },
];

export const TOP_COMB_DATA = [
  {
    bestPlaced: 7681,
    combinims: "11x",
    total_odds: 951.89,
    events: [
      {
        label: "Georgia",
        match: "Cyprus-Georgia",
      },
      {
        label: "Ukraine",
        match: "North Macedonia-Georgia",
      },
      {
        label: "Wales",
        match: "Cyprus-Armenia",
      },
    ],
  },
  {
    bestPlaced: 1115,
    combinims: "23x",
    total_odds: 500,
    events: [
      {
        label: "Albania",
        match: "Albania-Georgia",
      },
      {
        label: "Qatar",
        match: "North Macedonia-Qatar",
      },
      {
        label: "England",
        match: "England-Armenia",
      },
    ],
  },
  {
    bestPlaced: 4512,
    combinims: "18x",
    total_odds: 700.6,
    events: [
      {
        label: "China",
        match: "Cyprus-China",
      },
      {
        label: "USA",
        match: "USA-Georgia",
      },
      {
        label: "Brazil",
        match: "Cyprus-Brazil",
      },
    ],
  },
  {
    bestPlaced: 1111,
    combinims: "6x",
    total_odds: 120,
    events: [
      {
        label: "Thailand",
        match: "Cyprus-Thailand",
      },
      {
        label: "Japan",
        match: "Japan-Georgia",
      },
      {
        label: "Malta",
        match: "Cyprus-Malta",
      },
    ],
  },
];

export const NUMBER_REGEXP = /^[0-9\b.,]+$/;
export const SPORT_NAVBAR_INTERVAL_TIMING = 360000;
export const SPORT_CONFIG_INTERVAL_TIMING = 300000;
export const SPORT_SKIN_CONFIG_INTERVAL_TIMING = 60000;
export const SPORT_TOURNAMENT_INTERVAL_TIMING = 60000;
export const SPORT_EVENT_INTERVAL_TIMING = 60000;
export const SPORT_WIDGET_INTERVAL_TIMING = 60000;
export const CLEAR_VALIDATED_COUPON_MIN = 2;

export const REACT_APP_DEFAULT_LANGUAGE = "it";
export const REACT_APP_LANGUAGE_OPTIONS = [
  { locale: "en", header: "en-GB", dateLocale: "enLocale" },
  { locale: "it", header: "it-IT", dateLocale: "itLocale" },
  { locale: "es", header: "es-ES", dateLocale: "esLocale" },
  { locale: "pt", header: "pt-BR", dateLocale: "ptLocale" },
  { locale: "de", header: "de-DE", dateLocale: "deLocale" },
  { locale: "fr", header: "fr-FR", dateLocale: "frLocale" },
  { locale: "tr", header: "tr-TR", dateLocale: "trLocale" },
];

export const BINDING_LEGENDS = [
  { value: 0, label: "unique" },
  { value: 1, label: "single" },
  { value: 3, label: "triple" },
  { value: 5, label: "quintuple" },
];
