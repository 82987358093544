import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  playCouponRequested,
  playCouponSucceded,
  playCouponFailed,
  bookCouponRequested,
  bookCouponSucceded,
  bookCouponFailed,
  clearCouponState,
  getPlayedCouponRequested,
  getPlayedCouponSucceded,
  getPlayedCouponFailed,
  setProceedState,
  setBookState,
  getBookedCouponRequested,
  getBookedCouponSucceded,
  getBookedCouponFailed,
  getMyBetsRequested,
  getMyBetsSucceded,
  getMyBetsFailed,
  printPlayedCouponRequested,
  printPlayedCouponSucceded,
  printPlayedCouponFailed,
  confirmCouponRequested,
  confirmCouponSucceded,
  confirmCouponFailed,
  refuseCouponRequested,
  refuseCounponSucceded,
  refuseCounponFailed,
  checkCouponStateRequested,
  checkCouponStateFailed,
  checkCouponStateSucceded,
  getEventForQuickBetRequested,
  getEventForQuickBetSucceded,
  getEventForQuickBetFailed,
  verifyPlayedCouponRequested,
  showPlayedCouponReceipt,
  verifyPlayedCouponSucceded,
  verifyPlayedCouponFailed,
  cancelCouponRequested,
  cancelCouponSucceeded,
  cancelCouponFailed,
  payedCouponRequested,
  payedCouponFailed,
  payedCouponSucceded,
  getOddsUpdatesRequested,
  getOddsUpdatesSucceded,
  getOddsUpdatesFailed,
  getCashoutRequested,
  getCashoutConfirmRequested,
  getCashoutConfirmSucceded,
  getCashoutConfirmFailed,
  getCashoutSucceded,
  getCashoutFailed,
  clearCouponResponse,
} from "./couponSlice";
import {
  getSkinBettingRequested,
  saveBettingWalletId,
} from "../wallet/walletSlice";
import {
  getAuthBettingConfigFailed,
  getAuthBettingConfigRequested,
  getAuthBettingConfigSucceded,
  getBettingConfigRequested,
  getBettingConfigSucceded,
  getBettingConfigFailed,
} from "./couponSlice";
import { instanceAxios } from "../../services/axiosServices";
import { saveSkinData } from "../auth/authSlice";
import { store } from "../store";
export function* getConfig(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/skin/settings");
    const { data } = response.data;

    yield put(getBettingConfigSucceded(data.betting));
    yield put(saveSkinData(data.skin));
  } catch (error) {
    yield put(getBettingConfigFailed());
  }
}

export function* getAuthConfig({
  payload,
}: ReturnType<typeof getAuthBettingConfigRequested>): unknown {
  try {
    const response = yield yield call(
      instanceAxios.get,
      `/api/wallet/settings/${payload}`
    );
    const { data } = response.data;
    yield put(getAuthBettingConfigSucceded(data));
  } catch (error) {
    yield put(getAuthBettingConfigFailed());
  }
}

export function* getOddsUpdates({
  payload,
}: ReturnType<typeof getOddsUpdatesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `api/coupon/odds?params=${payload}`
    );
    const { data } = response.data;
    yield put(getOddsUpdatesSucceded(data));
  } catch (error) {
    yield put(getOddsUpdatesFailed());
  }
}
export function* playCoupon({
  payload,
}: ReturnType<typeof playCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/play`,
      payload
    );
    const { data } = response.data;
    yield put(playCouponSucceded(data));
    yield put(saveBettingWalletId(undefined));
    if (data.played) {
      yield put(
        getPlayedCouponRequested({ isRepeated: false, couponId: data.id })
      );
      yield put(showPlayedCouponReceipt(true));
    }
    yield put(clearCouponState());
    yield put(setProceedState(true));
  } catch (error) {
    yield put(playCouponFailed());
    yield put(setProceedState(true));
  }
}
export function* bookCoupon({
  payload,
}: ReturnType<typeof bookCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/book`,
      payload
    );
    const { data } = response.data;
    yield put(bookCouponSucceded(data));
    yield put(showPlayedCouponReceipt(true));
    yield put(clearCouponState());
    yield put(setBookState(true));
  } catch (error) {
    yield put(bookCouponFailed());
    yield put(setBookState(true));
  }
}
export function* printPlayedCoupon({
  payload,
}: ReturnType<typeof printPlayedCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/print/${payload}`
    );
    const { data } = response.data;
    yield put(printPlayedCouponSucceded(data));
  } catch (error) {
    yield put(printPlayedCouponFailed());
  }
}
export function* verifyPlayedCoupon({
  payload,
}: ReturnType<typeof verifyPlayedCouponRequested>): unknown {
  try {
    const serial = payload.serial ? payload.serial : payload;
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/verify/${serial}`,
      payload
    );
    const { data } = response.data;
    yield put(verifyPlayedCouponSucceded({ ...data, caller: payload.caller }));
  } catch (error) {
    yield put(verifyPlayedCouponFailed());
  }
}
export function* getPlayedCoupon({
  payload,
}: ReturnType<typeof getPlayedCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/get/${payload.couponId}`
    );
    const { data } = response.data;
    const dividerFromState = store.getState().auth.authWallet?.currency.divider;
    const divider = dividerFromState ? dividerFromState : 1;
    yield put(
      getPlayedCouponSucceded({
        isRepeated: payload.isRepeated,
        data: data,
        divider: divider,
      })
    );
  } catch (error) {
    yield put(getPlayedCouponFailed());
  }
}
export function* getBookedCoupon({
  payload,
}: ReturnType<typeof getBookedCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/booked/${payload}`,
      payload
    );
    const { data } = response.data;
    yield put(getBookedCouponSucceded(data));
  } catch (err) {
    console.error(err);
    yield put(getBookedCouponFailed());
  }
}
export function* getMyBets(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/coupon/last");
    yield put(getMyBetsSucceded(response.data.data));
  } catch (error) {
    yield put(getMyBetsFailed());
  }
}
export function* confirmCoupon({
  payload,
}: ReturnType<typeof confirmCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/confirm/${payload}`
    );
    const { data } = response.data;
    yield put(confirmCouponSucceded(data));
  } catch (err) {
    yield put(confirmCouponFailed());
  }
}
export function* refuseCoupon({
  payload,
}: ReturnType<typeof refuseCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/refuse/${payload}`
    );
    const { data } = response.data;
    yield put(refuseCounponSucceded(data));
  } catch (err) {
    yield put(refuseCounponFailed());
  }
}
export function* checkCouponState({
  payload,
}: ReturnType<typeof checkCouponStateRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/check/${payload}`
    );
    const { data } = response.data;

    yield put(checkCouponStateSucceded(data));
  } catch (error) {
    yield put(checkCouponStateFailed());
  }
}
export function* getEventForQuickBet({
  payload,
}: ReturnType<typeof getEventForQuickBetRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/sport/event/${payload}`
    );
    const { data } = response.data;
    let allQuickOdds: any = [];
    data.map((market: any) => {
      let newArray: any = [];
      market.odds
        .filter((odd: any) => odd.quick.length > 0)
        .map((odd: any) => {
          const newOdd = {
            ...odd,
            marketId: market.id,
            eventId: payload,
          };
          newArray = [...newArray, newOdd];
        });

      allQuickOdds = [...allQuickOdds, ...newArray];
    });

    yield put(getEventForQuickBetSucceded(allQuickOdds));
  } catch (error) {
    yield put(getEventForQuickBetFailed());
  }
}
export function* cancelCoupon({
  payload,
}: ReturnType<typeof cancelCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/cancel/${payload}`
    );
    const { data } = response.data;
    yield put(cancelCouponSucceeded(data));
  } catch (err) {
    yield put(cancelCouponFailed());
  }
}

export function* payedCoupon({
  payload,
}: ReturnType<typeof payedCouponRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/payed/${payload.couponId}`
    );
    const { data } = response.data;
    yield put(payedCouponSucceded(data));

    yield put(
      getSkinBettingRequested({
        id: payload.authId,
        params: payload.params,
      })
    );
  } catch (err) {
    yield put(payedCouponFailed());
  }
}

export function* getCashout({
  payload,
}: ReturnType<typeof getCashoutRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/cashout/verify/${payload}`
    );
    const { data } = response.data;
    yield put(getCashoutSucceded(data));
  } catch (err) {
    yield put(getCashoutFailed());
  }
}

export function* getCashoutConfirm({
  payload,
}: ReturnType<typeof getCashoutConfirmRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/coupon/cashout/confirm/${payload}`
    );
    const { data } = response.data;
    yield put(getCashoutConfirmSucceded(data));
    yield put(
      getSkinBettingRequested({ id: store.getState().auth.authWallet?.id })
    );
    yield put(getMyBetsRequested());
    yield put(clearCouponResponse());
    yield put(showPlayedCouponReceipt(false));
  } catch (err) {
    yield put(getCashoutConfirmFailed());
  }
}

export function* ongetBettingConfigRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getBettingConfigRequested, getConfig);
}
export function* ongetAuthBettingConfigRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getAuthBettingConfigRequested, getAuthConfig);
}
export function* ongetOddsUpdatesRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getOddsUpdatesRequested, getOddsUpdates);
}
export function* onplayCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(playCouponRequested, playCoupon);
}
export function* onbookCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(bookCouponRequested, bookCoupon);
}
export function* onprintPlayedCouponRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(printPlayedCouponRequested, printPlayedCoupon);
}
export function* onverifyPlayedCouponRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(verifyPlayedCouponRequested, verifyPlayedCoupon);
}

export function* ongetPlayedCouponRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getPlayedCouponRequested, getPlayedCoupon);
}
export function* ongetBookedRecoverRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getBookedCouponRequested, getBookedCoupon);
}

export function* ongetMyBetsRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getMyBetsRequested, getMyBets);
}

export function* onconfirmCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(confirmCouponRequested, confirmCoupon);
}

export function* onrefuseCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(refuseCouponRequested, refuseCoupon);
}

export function* oncheckCouponStateRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeEvery(checkCouponStateRequested, checkCouponState);
}
export function* ongetQuickBetRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getEventForQuickBetRequested, getEventForQuickBet);
}

export function* oncancelCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(cancelCouponRequested, cancelCoupon);
}

export function* onpayedCouponRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(payedCouponRequested, payedCoupon);
}

export function* ongetCashoutRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getCashoutRequested, getCashout);
}

export function* ongetCashoutConfirmRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getCashoutConfirmRequested, getCashoutConfirm);
}

export function* couponSagas(): unknown {
  yield all([
    call(ongetOddsUpdatesRequested),
    call(onplayCouponRequested),
    call(onbookCouponRequested),
    call(onprintPlayedCouponRequested),
    call(onverifyPlayedCouponRequested),
    call(ongetPlayedCouponRequested),
    call(ongetBookedRecoverRequested),
    call(ongetMyBetsRequested),
    call(onconfirmCouponRequested),
    call(onrefuseCouponRequested),
    call(oncheckCouponStateRequested),
    call(ongetQuickBetRequested),
    call(oncancelCouponRequested),
    call(onpayedCouponRequested),
    call(ongetBettingConfigRequested),
    call(ongetAuthBettingConfigRequested),
    call(ongetCashoutRequested),
    call(ongetCashoutConfirmRequested),
  ]);
}
