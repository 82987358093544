import React, { ReactElement, useState } from "react";
import styles from "./styles.module.scss"
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { handleOddsTypeChange } from "../../../redux/config/configSlice";
import { Translate } from "react-redux-i18n";

export default function OddType(): ReactElement {
  const dispatch = useAppDispatch()
  const oddsType = useAppSelector(state => state.config.oddsType)
  const [selectValue, setSelectValue] = useState<string>(oddsType)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value)
    dispatch(handleOddsTypeChange(event.target.value))
  }

  return (
    <Box className={styles.oddType}>
      <Typography className={styles.label}><Translate value="nav.oddsType" /></Typography>
      <Select
        value={selectValue}
        onChange={handleChange}
        className={styles.select}
      >
        <MenuItem value="decimal" className={styles.option}><Translate value="nav.decimal" /></MenuItem>
        <MenuItem value="american" className={styles.option}><Translate value="nav.american" /></MenuItem>
        <MenuItem value="fraction" className={styles.option}><Translate value="nav.fractional" /></MenuItem>
      </Select>
    </Box>
  )
}