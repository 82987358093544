import React, { ReactElement } from "react";
import { Translate } from "react-redux-i18n";
import { Box, Typography, } from "@mui/material";

import styles from "./styles.module.scss";
import FontAwesomeIcon from "../../shared/FontAwesomeIcon";

export default function AuthenticationMessage(): ReactElement {

  return (
    <Box className={styles.notAuthContainer}>
      <Typography className={styles.message}>
        <span className={styles.icon}><FontAwesomeIcon name="circle-info" type="fa" /></span>
        <Translate value="content.authRequired" /></Typography>
    </Box>
  );
}
