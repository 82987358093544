import React, { ReactElement, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Paper } from "@mui/material";
import styles from "./styles.module.scss";

import CouponRoute from "../../../routes/Coupon";
const Viewer = lazy(() => import("../../../routes/Viewer"));
const NotFound = lazy(() => import("../../../routes/NotFound"));
const LiveCalendar = lazy(() => import("../../../routes/LiveCalendar"));

export default function Content(): ReactElement {
  return (
    <Paper variant="outlined" className={styles.content}>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/live/calendar" element={<LiveCalendar />} />
          <Route path="/:view/:lang?/:token?" element={<Viewer />} />
          <Route path="/coupon/:lang?/:serial/:token?" element={<CouponRoute />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Paper >
  );
}
