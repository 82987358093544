import { all, call } from "redux-saga/effects";

import { authSagas } from "./auth/authSagas";
import { sportSagas } from "./sport/sportSagas";
import { liveSagas } from "./live/liveSagas";
import { couponSagas } from "./coupon/couponSagas";
import { configSagas } from "./config/configSagas";

export default function* rootSaga(): Generator<unknown, void, unknown> {
  yield all([
    call(authSagas),
    call(liveSagas),
    call(sportSagas),
    call(couponSagas),
    call(configSagas),
  ]);
}
