import React, { ReactElement } from "react";
import styles from "./styles.module.scss"
import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import { clearCashoutData, getCashoutConfirmRequested } from "../../../redux/coupon/couponSlice";
import { closeDialog } from "../../../redux/dialog/dialogSlice";

export default function Cashout(): ReactElement {
  const dispatch = useAppDispatch()
  const cashoutData = useAppSelector(state => state.coupon.cashout)
  const cashoutState = useAppSelector(state => state.coupon.cashoutState)
  const couponId = useAppSelector(state => state.dialog.dialogData)
  const currency = useAppSelector(state => state.auth.authWallet?.currency.symbol)

  const handleCancel = () => {
    dispatch(clearCashoutData())
    dispatch(closeDialog())
  }

  const handleProceed = () => {
    dispatch(getCashoutConfirmRequested(couponId))
  }
  return (
    <>
      {cashoutData &&
        <Box className={styles.cashoutContainer}>
          {
            (cashoutData.status === false || cashoutState === "confirm") ?
              <Typography className={styles.cashoutLabel}>{cashoutData.message}</Typography>
              :
              <Typography className={styles.cashoutLabel}>
                <Translate value="coupon.cashoutValue" /> : &nbsp;
                <span className={styles.cashoutValue}>{cashoutData.cashout} {currency}</span>
              </Typography>
          }
          <Box className={styles.actionButtons}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancel}
              className={styles.actionButton}
            >
              {cashoutData.status === true && cashoutState === "confirm" ? <Translate value="coupon.close" /> : <Translate value="coupon.cancel" />}
            </Button>
            {cashoutData.status === false || cashoutState === "verify" &&
              <Button
                variant="contained"
                color="success"
                onClick={handleProceed}
                className={styles.actionButton}
              >
                <Translate value="coupon.confirm" />
              </Button>
            }
          </Box>
        </Box>
      }
    </>
  )
}