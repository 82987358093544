import React, { Fragment, ReactElement, useState, } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Typography } from "@mui/material";

import { IPalimpsestItem } from "../../../redux/sport/sportSlice";
import { removeEventFromCoupon } from "../../../redux/coupon/couponSlice";
import { convertBeginToDate } from "../../../services/utils";
import styles from "./styles.module.scss";

interface IProps {
  eventId: string,
}
function EventLabel({ eventId }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const home = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].home);
  const away = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].away);

  const data = convertBeginToDate(useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].begin)).split(" ")[0];
  const time = convertBeginToDate(useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].begin)).split(" ")[1];
  const short = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].short);

  const tournament = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].tournament_id);
  const tournamentLabel = useAppSelector((state) => tournament && state.live.palimpsest && Object.values(state.live.palimpsest.tournaments).find((el: IPalimpsestItem) => el.id === tournament)?.label);

  const category = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].category_id);
  const categoryLabel = useAppSelector((state) => state.live.palimpsest.categories && Object.values(state.live.palimpsest.categories).find((el: IPalimpsestItem) => el.id === category)?.label);

  const lives = useAppSelector((state) => state.live.palimpsest) || []
  const caller = useAppSelector(state => state.coupon.coupon[eventId][0].caller);
  const coupon = useAppSelector(state => state.coupon.coupon);
  const sports = useAppSelector(state => state.sport.sports) || [];

  const sportId = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId] && state.live.eventsConfig[eventId].sport_id);
  const sportLabel = useAppSelector((state) => state.live.palimpsest.sports && Object.values(state.live.palimpsest.sports).find((el: IPalimpsestItem) => el.id === sportId)?.label)

  const outrights = useAppSelector(state => state.sport.outrights) || [];

  const [infoEvent, setInfoEvent] = useState(false)

  const handlerClickEvent = () => {
    setInfoEvent(!infoEvent)
  }

  const handleDeleteEvents = () => {
    dispatch(removeEventFromCoupon(eventId))
  }

  const findEventInSportData = (id: number | undefined, sports: IPalimpsestItem[]) => {
    let result: IPalimpsestItem | undefined;
    sports.forEach((sport) => {
      if (sport.children) {
        sport.children.forEach((category) => {
          if (category.children) {
            category.children.forEach((tournament) => {
              if (tournament.children) {
                tournament.children.forEach((event) => {
                  if (event.id === id) {
                    result = {
                      ...event,
                      tournamentName: tournament.label,
                      categoryName: category.label,
                      sportName: sport.label,
                    };
                  }
                });
              }
            });
          }
        });
      }
    });
    return result;
  };
  const findDate = convertBeginToDate(findEventInSportData(Number(eventId), sports)?.begin).split(" ")[0] ||
    convertBeginToDate(findEventInSportData(Number(eventId), outrights)?.begin).split(" ")[0];
  const homeInSport = findEventInSportData(Number(eventId), sports)?.home;

  return (
    <Fragment>
      <Box className={styles.event}>
        <Box className={styles.eventLabelContainer}>
          <Box
            onClick={handlerClickEvent}
            className={styles.infoIcon}>
            <InfoIcon />
            {/* <img src={infoIcon} className={styles.icon} /> */}
          </Box>

          <Typography className={`${styles.eventLabel} ${!(homeInSport || away) && styles.fullWidthLabel}`} textAlign="end">
            {
              caller === "live" ?
                home
                : (caller === "sport" && sports && outrights) ?
                  homeInSport ||
                  findEventInSportData(Number(eventId), outrights)?.home
                  : coupon ?
                    home
                    : eventId
            }
          </Typography>
          {(homeInSport || away) && <Typography className={styles.eventLabelDivider}>-</Typography>}
          {(homeInSport || away) &&
            <Typography className={styles.eventLabel} >
              {
                caller === "live" ?
                  away
                  : (caller === "sport" && sports && outrights) ?
                    findEventInSportData(Number(eventId), sports)?.away ||
                    findEventInSportData(Number(eventId), outrights)?.away
                    : coupon ?
                      away
                      : eventId
              }
            </Typography>
          }
          <Box
            onClick={handleDeleteEvents}
            className={styles.trashIcon}>
            {/* <img
              color="primary"
              src={recycleBin}
            /> */}
            <DeleteOutlineIcon />
          </Box>
        </Box>
      </Box>


      {/* info event */}
      {
        infoEvent &&
        <Box className={styles.eventInfoContainer}>
          <Box className={styles.eventShortContainer}>
            <Box className={styles.eventInfo}>
              <Typography className={styles.eventLabelInfo}>
                {
                  caller === "live" ?
                    home
                    : (caller === "sport" && sports && outrights) ?
                      homeInSport ||
                      findEventInSportData(Number(eventId), outrights)?.home
                      : coupon ?
                        home
                        : eventId
                }
              </Typography>
              {homeInSport && <Typography className={styles.eventLabelDividerInfo}>-</Typography>}
              <Typography className={styles.eventLabelInfo}>
                {
                  caller === "live" ?
                    away
                    : (caller === "sport" && sports && outrights) ?
                      findEventInSportData(Number(eventId), sports)?.away ||
                      findEventInSportData(Number(eventId), outrights)?.away
                      : coupon ?
                        away
                        : eventId
                }
              </Typography>
            </Box>

            <Typography className={caller === "sport" ? styles.numberInfo : ''}>
              {caller === "live" ?
                short
                : (caller === "sport" && sports && outrights && lives) ?
                  findEventInSportData(Number(eventId), sports)?.short ||
                  findEventInSportData(Number(eventId), outrights)?.short
                  : coupon ?
                    short
                    : eventId
              }
            </Typography>
          </Box>

          <Box className={styles.couponInfo}>
            <Typography className={styles.sportLabel}>
              {caller === "live" ?
                sportLabel
                : (caller === "sport" && lives && outrights) ?
                  findEventInSportData(Number(eventId), sports)?.sportName ||
                  findEventInSportData(Number(eventId), outrights)?.sportName
                  : coupon ?
                    sportLabel
                    : eventId
              }
            </Typography>
            <Typography className={styles.labelInfo}>
              {caller === "live" ?
                data
                : (caller === "sport" && sports && outrights) ?
                  findDate
                  : coupon ?
                    data
                    : eventId
              }
            </Typography>
          </Box>

          <Box className={styles.categoryTime}>
            <Typography className={styles.categoryLabel}>
              {
                caller === "live" ?
                  categoryLabel
                  : (caller === "sport" && lives && outrights) ?
                    findEventInSportData(Number(eventId), sports)?.categoryName ||
                    findEventInSportData(Number(eventId), outrights)?.categoryName
                    : coupon ?
                      categoryLabel
                      : eventId
              }
            </Typography>
            <Typography className={styles.labelInfo}>
              {
                caller === "live" ?
                  time?.slice?.(0, 5)
                  : (caller === "sport" && sports && outrights) ?
                    convertBeginToDate(findEventInSportData(Number(eventId), sports)?.begin).split(" ")[1].slice(0, 5) ||
                    convertBeginToDate(findEventInSportData(Number(eventId), outrights)?.begin).split(" ")[1].slice(0, 5)
                    : coupon ?
                      time
                      : eventId
              }
            </Typography>
          </Box>

          <Box className={styles.categoryShort}>
            <Typography className={styles.tournamentLabel}>
              {
                caller === "live" ?
                  tournamentLabel
                  : (caller === "sport" && lives && outrights) ?
                    findEventInSportData(Number(eventId), sports)?.tournamentName ||
                    findEventInSportData(Number(eventId), outrights)?.tournamentName
                    : coupon ?
                      tournamentLabel
                      : eventId
              }
            </Typography>

          </Box>
        </Box>
      }
    </Fragment >
  )
}
export default EventLabel;