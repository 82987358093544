import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton'

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import { removeOddFromCoupon, ICouponOdd } from "../../../redux/coupon/couponSlice";
import { recursiveSearchByType } from "../../../services/utils";
import EventLabel from "../EventLabel";
import OddBox from "../OddBox";
import styles from "./styles.module.scss";

interface IProps {
  eventId: string,
  data: ICouponOdd[],
}

function SingleEvent({ eventId, data }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  // const eventInCoupon = useAppSelector((state) => state.coupon.coupon && state.coupon.coupon[eventId]);
  // const noLiveEvent = useAppSelector((state) => state.live.eventsConfig && !state.live.eventsConfig[eventId]);
  const liveEvent = useAppSelector((state) => state.live.eventsConfig && state.live.eventsConfig[eventId]);
  const sportEvent = useAppSelector((state) =>
    state.sport.sports &&
    state.sport.outrights &&
    (recursiveSearchByType(Number(eventId), state.sport.sports, "e") || recursiveSearchByType(Number(eventId), state.sport.outrights, "e")));
  // const noSportEvent = useAppSelector((state) =>
  //   state.sport.sports &&
  //   state.sport.outrights &&
  //   !(recursiveSearchByType(Number(eventId), state.sport.sports, "e") || recursiveSearchByType(Number(eventId), state.sport.outrights, "e")));
  const handleOddRemove = (eventId?: number, unique?: string, value?: number) => {
    dispatch(
      removeOddFromCoupon({
        eventId: eventId,
        unique: unique,
        value: value
      }))
  }

  return (
    <Box className={styles.oddsEventContainer}>
      {liveEvent ?
        <Box className={styles.liveEvent}>
          <Typography className={styles.callerLabelLive}>LIVE</Typography>
        </Box>
        : sportEvent ?
          <Box className={styles.sportEvent}>
            <Typography className={styles.callerLabelSport}>SPORT</Typography>
          </Box>
          : null
      }
      <Box className={styles.event}>
        <EventLabel eventId={eventId} />
      </Box>
      {
        data?.map((odd) => {
          return (
            <Box
              key={odd.unique}
              className={styles.oddsItemContainer}>
              <Box className={styles.labelContainer}>
                <Box className={styles.marketLabelButton}>
                  <Typography className={styles.oddLabel}>
                    {odd.marketLabel}
                  </Typography>
                </Box>
                <Box className={styles.oddLabelButton}>
                  <Typography className={styles.oddLabelContainer}>
                    {odd.oddLabel}
                  </Typography>
                  <Typography className={styles.oddLabelContainer}>
                    {odd.spreadLabel}
                  </Typography>
                </Box>
              </Box>
              <OddBox odd={odd} />
              <IconButton
                onClick={() => handleOddRemove(odd.eventId, odd.unique, odd.value)}
                className={styles.closeBtn}
              >
                <FontAwesomeIcon type="fal" name="xmark" margin={1} />
              </IconButton>
            </Box>
          )
        })
      }
    </Box>
  )
}
export default SingleEvent;