
import React, { ReactElement, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Typography } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import { getBookedCouponRequested, clearNoCouponAvailableMsg } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";

function CouponLoad(): ReactElement {
  const dispatch = useAppDispatch();
  const lang = useAppSelector(state => state.auth.locale);
  const closedEventsMsg = useAppSelector(state => state.coupon.closedEventsMsg);
  const [couponId, setCouponId] = useState<string | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCouponId(event.target.value);
  }
  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(getBookedCouponRequested(couponId))
    }
  }
  const handleLoad = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(getBookedCouponRequested(couponId))
  }
  useEffect(() => {
    return () => {
      dispatch(clearNoCouponAvailableMsg())
    }
  }, [])


  return (
    <Box className={styles.noOddsContainer}>
      <Typography className={styles.noOddsLabel}>
        <Translate value="coupon.emptyMessage" />
      </Typography>
      <form
        onSubmit={(event) => handleLoad(event)}
        className={styles.searchAndBtnContainer}
      >
        <TextField
          required
          color="secondary"
          placeholder={
            lang === "it" ?
              "Codice Prenotazione" :
              lang === "es" ?
                "Código de reserva" :
                lang === "pt" ?
                  "Código reserva" :
                  lang === "zh" ?
                    "預訂代碼" :
                    lang === "fr" ?
                      "Numéro de réservation" :
                      lang === "de" ?
                        "Buchungsnummer" :
                        lang === "ar" ?
                          "رمز الحجز" :
                          lang === "sq" ?
                            "Kodi i rezervimit"
                            : "Booking code"
          }
          onChange={(event) => handleChange(event)}
          onKeyDown={(event) => handleEnter(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box className={styles.searchIcon}>
                  <FontAwesomeIcon name="magnifying-glass" type="fas" />
                </Box>
              </InputAdornment>
            )
          }}
          className="form-label"
        />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={styles.loadBtn}
        >
          <Translate value="coupon.load" />
        </Button>
      </form>
      {
        closedEventsMsg &&
        <Typography className={styles.noEvents}>
          {closedEventsMsg &&
            <Translate value="coupon.closedEventsMsg" />
          }
        </Typography>
      }

    </Box >
  );
}

export default CouponLoad;

