import { IPalimpsestItem } from "../redux/sport/sportSlice";
import { SetStateAction, useLayoutEffect, useState } from "react";
import { Palimpsest } from "../redux/live/liveSlice";
import { format } from "date-fns";
import { store } from "../redux/store";
import { Odds } from "./oddslib";

export const recursiveSearch = (
  tournamentId: number,
  data: any
): IPalimpsestItem | null => {
  for (let i = 0; i < data?.length; i++) {
    if (data[i].id === tournamentId) {
      return data[i];
    }
    if (data[i].children) {
      const result = recursiveSearch(tournamentId, data[i].children);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const recursiveSearchByType = (
  id: number,
  data: any,
  type: string,
  type2?: string
): IPalimpsestItem | null => {
  for (let i = 0; i < data?.length; i++) {
    if (
      data[i].id === id &&
      (data[i].type === type || data[i].type === type2)
    ) {
      return data[i];
    }
    if (data[i].children) {
      const result = recursiveSearchByType(id, data[i].children, type, type2);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const filterTreeData = (
  item: IPalimpsestItem,
  filterValue?: number
): boolean | number | undefined => {
  if (
    filterValue &&
    item.begin &&
    Math.round(new Date().getTime() / 1000) < item.begin &&
    item.begin < filterValue
  )
    return true;

  if (item.children) {
    return item.children
      .map((copy: IPalimpsestItem) => copy)
      .filter((item) => filterTreeData(item, filterValue)).length;
  }
};

export const recursiveFunction = (
  data: IPalimpsestItem[],
  filterValue: number
): IPalimpsestItem[] => {
  // Filter the data array
  return data
    ?.map((sport: IPalimpsestItem) => {
      const updatedSport = { ...sport };
      if (
        updatedSport.children &&
        updatedSport.children[0].begin !== undefined
      ) {
        const filteredEvents = updatedSport.children.filter(
          (event) =>
            filterValue &&
            event.begin &&
            Math.round(new Date().getTime() / 1000) < event.begin &&
            event.begin < filterValue
        );
        updatedSport.children = filteredEvents;
      } else if (updatedSport.children) {
        updatedSport.children = recursiveFunction(
          updatedSport.children,
          filterValue
        );
      }

      return updatedSport;
    })
    .filter((updatedSport: IPalimpsestItem) => {
      return updatedSport.children && updatedSport.children.length > 0;
    });
};

export const isObjectEmpty = (obj: Record<string, any>): boolean => {
  return Object.keys(obj).length === 0;
};

/*remove duplicates from array of numbers*/
export const removeDuplicates = (arr: number[]): number[] => {
  const uniqueArray = arr.filter((elem, index, self) => {
    return (
      index ===
      self.findIndex((t) => {
        return t === elem;
      })
    );
  });
  return uniqueArray;
};

/*sort  items by order attribute*/
export const sortDataByOrderAttribute = (data: Palimpsest[]): Palimpsest[] => {
  return data.sort((firstItem, secondItem) =>
    firstItem.order > secondItem.order
      ? 1
      : firstItem.order < secondItem.order
      ? -1
      : 0
  );
};

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([window.innerWidth]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const checkWalletPermissions = (
  permissions: string[],
  permissionsValue: string
): boolean => {
  const value = permissions?.some((item: string) => {
    return item === permissionsValue;
  });
  return Boolean(value);
};
export const convertBeginToDate = (begin: number | undefined) => {
  const timeStamp = begin && new Date(begin * 1000);
  const year = timeStamp && timeStamp.getFullYear();
  const month =
    timeStamp && (timeStamp.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = timeStamp && timeStamp.getDate().toString().padStart(2, "0");
  const hours = timeStamp && timeStamp.getHours().toString().padStart(2, "0");
  const minutes =
    timeStamp && timeStamp.getMinutes().toString().padStart(2, "0");
  const seconds =
    timeStamp && timeStamp.getSeconds().toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};
export const objectEmpty = (obj: Record<string, any>): boolean => {
  return Object.keys(obj).length === 0;
};

export const groupedEventsByDate = (
  events: IPalimpsestItem[]
): Record<string, IPalimpsestItem[]> => {
  const groupedEvents = events?.reduce((dataGroupedByDate: any, event: any) => {
    const fullTime = event && convertBeginToDate(event.begin);
    const date = fullTime && fullTime.split(" ")[0];
    if (!dataGroupedByDate[date]) {
      dataGroupedByDate[date] = [];
    }
    dataGroupedByDate[date].push(event);
    return dataGroupedByDate;
  }, {});
  return groupedEvents;
};
export const handleScroll = (
  e: any,
  func: React.Dispatch<SetStateAction<any>>,
  initialItemsToLoad: number,
  itemsToLoad: number
) => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop - 500 < e.target.clientHeight;
  if (bottom) {
    func(initialItemsToLoad + itemsToLoad);
  }
};

export const convertDateToEurpoean = (date: string): string => {
  return new Date(date).toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const convertTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return format(date, "dd-MM-yy HH:mm");
};

export const containsValue = (array: string[], value: string) => {
  // Loop through each string in the array
  for (let i = 0; i < array.length; i++) {
    // Split the string by '|' character
    const parts = array[i].split("|");
    // Check if any part matches the desired value
    for (let j = 0; j < parts.length; j++) {
      if (parts[j] === value) {
        // If found, return true
        return true;
      }
    }
  }
  return false;
};

export const convertOddValue = (oddValue: number): string => {
  const oddType = store.getState().config.oddsType;
  let oddNewValue;
  switch (oddType) {
    case "american":
      oddNewValue = Math.ceil(
        Number(Odds.from("decimal", oddValue).to("moneyline"))
      ).toString();
      break;
    case "fraction":
      oddNewValue = Odds.from("decimal", oddValue)
        .to("fractional", { precision: 1 })
        .toString();
      break;
    default:
      oddNewValue = oddValue.toFixed(2).toString();
      break;
  }
  return oddNewValue;
};
