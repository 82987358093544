
import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

import GroupsGrid from "../GroupsGrid";
import SharedView from "../SharedView";
import TabPanel from "../../shared/TabPanel";
import { saveCouponTypeTabValue } from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";

function CouponTypeTabs(): ReactElement {
  const dispatch = useAppDispatch();
  const coupon = useAppSelector(state => state.coupon.coupon);
  const couponLength = useAppSelector(state => state.coupon.couponLength);
  const couponTypeTab = useAppSelector(state => state.coupon.couponTypeTab);
  const nrEventsInCoupon = Object.keys(coupon).length;

  const handleChange = (newValue: number) => {
    dispatch(saveCouponTypeTabValue(newValue));
  };

  useEffect(() => {
    if (!(couponLength > 1 && (couponLength === nrEventsInCoupon))) {
      dispatch(saveCouponTypeTabValue(0));
    }
  }, [couponLength])

  return (
    <Box className={styles.tabsContainer}>
      <Box className={styles.tabs}>
        {/* single tab*/}
        {couponLength === 1 &&
          <Button
            variant="contained"
            onClick={() => handleChange(0)}
            className={`${styles.tabButton} ${couponTypeTab === 0 ? styles.tabButtonActive : null}`}>
            <Typography className={styles.couponTypeLabel}>
              <Translate value="coupon.single" />
            </Typography>

          </Button>
        }
        {/* multiple tab */}
        {couponLength > 1 && (couponLength === nrEventsInCoupon) &&
          <Button
            variant="contained"
            onClick={() => handleChange(0)}
            className={`${styles.tabButton} ${couponTypeTab === 0 ? styles.tabButtonActive : null}`}>
            <Typography className={styles.couponTypeLabel}>
              <Translate value="coupon.multiple" />
            </Typography>
          </Button>
        }
        {/* system tab */}
        {couponLength > 1 && (couponLength === nrEventsInCoupon) &&
          <Button
            variant="contained"
            onClick={() => handleChange(1)}
            className={`${styles.tabButton} ${couponTypeTab === 1 ? styles.tabButtonActive : null}`}>
            <Typography className={styles.couponTypeLabel}>
              <Translate value="coupon.system" />
            </Typography>
          </Button>
        }
        {/* integral tab */}
        {couponLength > 1 && (couponLength !== nrEventsInCoupon) &&
          <Button
            variant="contained"
            onClick={() => handleChange(1)}
            className={`${styles.tabButton} ${couponTypeTab === 0 ? styles.tabButtonActive : null}`}>
            <Typography className={styles.couponTypeLabel}>
              <Translate value="coupon.integral" />
            </Typography>
          </Button>
        }
      </Box >

      {/** ***************single view *****************/}
      {couponLength === 1 &&
        <TabPanel value={couponTypeTab} index={0}>
          <SharedView />
        </TabPanel>
      }
      {/** ***************multiple view *****************/}
      {couponLength > 1 && (couponLength === nrEventsInCoupon) &&
        <TabPanel value={couponTypeTab} index={0}>
          <SharedView />
        </TabPanel>
      }
      {/** ***************system view *****************/}
      {couponLength > 1 && (couponLength === nrEventsInCoupon) &&
        <TabPanel value={couponTypeTab} index={1} >
          <GroupsGrid />
          <SharedView />
        </TabPanel>
      }
      {/** ***************integral view *****************/}
      {couponLength > 1 && (couponLength !== nrEventsInCoupon) &&
        <TabPanel value={couponTypeTab} index={0}>
          <GroupsGrid />
          <SharedView />
        </TabPanel>
      }
    </Box>

  );
}

export default CouponTypeTabs;