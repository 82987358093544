import React, { ReactElement, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import CouponLoad from "../CouponLoad";
import CouponFetchingView from "../CouponFetchingView";
import CouponReproposeView from "../CouponReproposeView";
import CouponInValidationView from "../CouponInValidationView";
import CouponValidatedView from "../CouponValidatedView";
import CouponRefusedView from "../CouponRefusedView";
import CouponVerify from "../CouponVerify";
import EventsOverview from "../EventsOverview";
import CouponTypeTabs from "../CouponTypeTabs";
import Hourglass from "../../shared/Hourglass";
import { triggerBackdropVisibility } from "../../../redux/coupon/couponSlice";
import { isObjectEmpty } from "../../../services/utils";
import styles from "./styles.module.scss";

function Coupon(): ReactElement {
  const dispatch = useAppDispatch();
  const couponRef = React.useRef<HTMLElement>();
  const inputHeight = couponRef.current?.offsetHeight

  const isFetchingPlayedCoupon = useAppSelector(state => state.coupon.isFetchingPlayedCoupon);
  const bettingLiveTimeout = useAppSelector(state => state.coupon.bettingConfig?.setting.bet_timeout_live)
  const [counter, setCounter] = useState<number | undefined>(bettingLiveTimeout)
  const [height, setHeight] = useState<number | undefined>(undefined)
  const backdropVisibility = useAppSelector(state => state.coupon.backdropVisibility)
  const couponLength = useAppSelector(state => state.coupon.couponLength);
  const playedCouponsStates = useAppSelector(state => state.coupon.playedCouponsStates);


  useEffect(() => {
    dispatch(triggerBackdropVisibility(false))
  }, [])

  useEffect(() => {
    setHeight(inputHeight)
  }, [inputHeight, couponRef])

  useEffect(() => {
    setCounter(bettingLiveTimeout)
  }, [backdropVisibility === false])

  useEffect(() => {
    const intervalId = setInterval(() => {
      counter && counter > 0 && setCounter(counter - 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [counter])

  return (
    <>
      <Box
        ref={couponRef}
        className={styles.couponContainer}
      >
        {/* backdrop before initiate be call in live */}
        {backdropVisibility &&
          <Box className={styles.backdropContainer}>
            <Box
              sx={{ height: (height && height) }}
              className={styles.backdrop} >
              <Hourglass />
              <Typography className={styles.backdropText}>
                <Translate value="coupon.waiting" />
                ...
              </Typography>
              <Typography className={styles.backdropText}>
                {counter}
              </Typography>
            </Box>
          </Box>
        }
        {/* backdrop when fetching coupon */}
        {
          isFetchingPlayedCoupon &&
          <>
            <CouponFetchingView />
            <Box className={styles.backdropContainer}>
              <Box
                sx={{ height: (height && height + 50) }}
                className={styles.backdrop}
              >
              </Box>
            </Box>
          </>
        }
        {
          playedCouponsStates &&
          !isObjectEmpty(playedCouponsStates) &&
          <Box className={styles.responseViewsContainer}>
            {
              playedCouponsStates &&
              Object
                .values(playedCouponsStates)
                .map(couponState => {
                  return (
                    <Box key={couponState.id}>
                      {
                        couponState?.acceptance ?
                          couponState?.user_confirm ?
                            <CouponReproposeView couponState={couponState} />
                            :
                            <CouponInValidationView couponState={couponState} />
                          :
                          couponState?.played ?
                            <CouponValidatedView couponState={couponState} />
                            :
                            <CouponRefusedView couponState={couponState} />
                      }
                    </Box>
                  )
                })
            }
          </Box>
        }

        {
          couponLength !== 0 ?
            <>
              {/* coupon inner content */}
              <EventsOverview />
              <CouponTypeTabs />
            </>
            :
            <Box className={styles.emptyAndVerifyContainer}>
              <CouponLoad />
              <CouponVerify />
            </Box>
        }


      </Box>
    </>
  );
}

export default Coupon;
