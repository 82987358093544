import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import SingleEvent from "../SingleEvent";
import { getOddsUpdatesRequested } from "../../../redux/coupon/couponSlice"

function Events(): ReactElement {
  const dispatch = useAppDispatch();
  const coupon = useAppSelector(state => state.coupon.coupon);
  const odds = useAppSelector(state => state.coupon.odds);

  useEffect(() => {
    odds.length > 0 &&
      dispatch(
        getOddsUpdatesRequested(JSON.stringify({
          odds: odds.map(obj => obj.code)
        }))
      );
  }, [])

  return (
    <>
      {
        Object.keys(coupon).map((key) => {
          return (
            <>
              {
                coupon[key] &&
                coupon[key].length > 0 &&
                <SingleEvent
                  eventId={key}
                  data={coupon[key]}
                />
              }
            </>
          )
        })
      }
    </>
  )
}
export default Events;