
import React, { ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import {
  PlayedCouponState,
  getPlayedCouponRequested,
  showPlayedCouponReceipt,
  updatePlayedCuponStates
} from "../../../redux/coupon/couponSlice";
import styles from "./styles.module.scss";
import { CLEAR_VALIDATED_COUPON_MIN } from "../../../constants";

interface IProps {
  couponState: PlayedCouponState
}

function CouponValidatedView({ couponState }: IProps): ReactElement {
  const dispatch = useAppDispatch();

  const handleReplayCoupon = () => {
    dispatch(
      getPlayedCouponRequested({
        isRepeated: true,
        couponId: couponState.id
      }))
    dispatch(updatePlayedCuponStates(couponState.id))

  }
  const handlePrintCoupon = () => {
    dispatch(getPlayedCouponRequested({ isRepeated: false, couponId: couponState.id }))
    dispatch(showPlayedCouponReceipt(true))
    dispatch(updatePlayedCuponStates(couponState.id))
  }
  const handleRemoveCoupon = () => {
    dispatch(updatePlayedCuponStates(couponState.id))
  }

  useEffect(() => {
    if (!couponState.acceptance) {
      const checkInterval = setInterval(() => {
        dispatch(updatePlayedCuponStates(couponState.id))
      }, CLEAR_VALIDATED_COUPON_MIN * 60 * 1000);
      return () => {
        clearInterval(checkInterval);
      }
    }
  }, [couponState])

  return (
    <Box className={styles.container} >
      <Box className={styles.textdiv}>
        <FontAwesomeIcon
          type="far"
          name="info-circle"
          margin={1}
          color="white"
        />
        <Typography className={styles.text}>
          <Translate value="coupon.playedFirst" />
          &nbsp;{couponState.serial}&nbsp;
          <Translate value="coupon.playedSecond" />
        </Typography>
      </Box>
      <Box className={styles.btncontainer}>
        <Button
          variant="contained"
          color="success"
          startIcon={<FontAwesomeIcon type="fal" name="rotate-right" />}
          onClick={() => handleReplayCoupon}
          className={styles.button}
        >
          <Typography className={styles.buttonLabel}>
            <Translate value="coupon.repeat" />
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon type="fal" name="eye" />}
          onClick={() => handlePrintCoupon()}
          className={styles.button}
        >
          <Typography className={styles.buttonLabel}>
            <Translate value="coupon.view" />
          </Typography>
        </Button>
        <Button
          color="error"
          variant="contained"
          startIcon={<FontAwesomeIcon type="fal" name="close" />}
          onClick={() => handleRemoveCoupon()}
          className={styles.button}
        >
          <Typography className={styles.buttonLabel}>
            <Translate value="coupon.close" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default CouponValidatedView;
